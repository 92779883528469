import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import DataTable from '../library/tableComponents/DataTable'
import ApiService from '../../services/APIService'
import { AuthContext } from '../library/pageComponents/AuthContext'
import ExcelIcon from '../icons/ExcelIcon'
import { GrafanaAnswer } from '../utils/GrafanaAnswer'

class InstagramPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      headLine: [getString('MENU_INSTAGRAM_HANDLES'),
        getString('INSTAGRAM_HEADLINE_PERSONAL_NUMBER'),
        getString('INSTAGRAM_HEADLINE_HEAD_FIRSTNAME'),
        getString('INSTAGRAM_HEADLINE_LASTNAME')],
      dataLines: [],
      fetchDate: ''
    }
  }

  componentDidMount () {
    this.updateStats()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async updateStats () {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }

      this.setState({ isLoading: true })

      const apiResult = await ApiService.getInstagramHandles(newAuth)
      const grafanaAnswer = new GrafanaAnswer(apiResult.handles)
      const dataLines = [...grafanaAnswer.records()].map(record =>
        [record.handle, record.activationCode, record.firstname, record.lastname])

      this.setState({
        dataLines: dataLines,
        fetchDate: (new Date(apiResult.fetchDate)).toLocaleString(),
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader () {
    const { fetchDate } = this.state

    return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
      <Grid item xs={6}>
        {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
      </Grid>
    </Grid>)
  }

  renderBody () {
    const { headLine, dataLines, fetchDate } = this.state
    const hasData = dataLines && dataLines.length > 0
    const filenamePrefix = 'InstagramHandles'

    return (
      <React.Fragment>

        {hasData && <DataTable
          headLine={headLine}
          dataLines={dataLines}
          fetchDate={fetchDate}
          excelTitle={getString('MENU_INSTAGRAM_HANDLES')}
          icon={<ExcelIcon style={{ color: '#1f7144' }}/>}
          filenamePrefix={filenamePrefix}/>}
      </React.Fragment>
    )
  }

  render () {
    if (this.state.isLoading) {
      return (<WaitForLoad/>)
    } else {
      return (<Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}
        {this.renderBody()}
      </Grid>)
    }
  }
}

InstagramPage.propTypes = {
  tabActions: PropTypes.object
}

InstagramPage.contextType = AuthContext

export default InstagramPage
