import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import getString, { target } from '../../config/strings'
import QRCode from '../library/QRCode'
import OptionsPanel from './OptionsPanel'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

const formatDate = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L') : ''
}

const formatDateTime = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L LT') : ''
}

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  tabSelected: {
    backgroundColor: '#f5f5f5'
  },
})

const EXAMINATIONS = [
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 0, 26).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 0, 27).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 3, 20).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 3, 21).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 3, 28).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 3, 29).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 6, 6).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 6, 7).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 6, 14).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 6, 15).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 9, 20).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 9, 21).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 9, 26).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 9, 27).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 10, 29).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 10, 30).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 11, 1).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 11, 2).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 11, 15).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2021, 11, 16).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 2, 30).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 2, 31).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 3, 27).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 3, 28).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 6, 27).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 6, 28).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 10, 29).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2022, 10, 30).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2023, 3, 27).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2023, 10, 29).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2023, 10, 30).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2024, 3, 25).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2024, 7, 28).toJSON().replace(/\.000Z$/, 'Z') },
  { courseName: 'ErgoPraktiker', examinationDate: new Date(2024, 11, 3).toJSON().replace(/\.000Z$/, 'Z') }
].sort((b, a) => (a.examinationDate > b.examinationDate) ? 1 : (a.examinationDate < b.examinationDate) ? -1 : 0)

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function namesFromEmail (email) {
  let result = [null, null]

  let userPart = email.substring(0, email.lastIndexOf('@'))
  if (userPart.indexOf('.') > 0) {
    let [firstname, lastname] = userPart.split('.', 2)
    if (firstname) {
      result[0] = firstname.charAt(0).toUpperCase() + firstname.slice(1)
    }
    if (lastname) {
      result[1] = lastname.charAt(0).toUpperCase() + lastname.slice(1)
    }
  }

  return result
}

function buildSysMessage (record) {
  return {
    systemAction: 'setCourseActive',
    actionParams:
      {
        email: record.email,
        courseName: record.courseName,
        token: record.onetimeToken
      }
  }
}

function enhanceExamination (record) {
  if (!record.courseName || !record.examinationDate) {
    record.examination = null
    return
  }
  record.examination = {
    courseName: record.courseName,
    examinationDate: record.examinationDate
  }
}

class BookingDialog extends PureComponent {

  constructor (props) {
    super(props)

    const record = props.record
    enhanceExamination(record)

    this.state = {
      actRecord: record,
      isSubmitting: false,
      choosenTab: record.onetimeToken ? 1 : 0,
      readOnly: Boolean(record.onetimeToken),
      hasDetails: record.comment || record.company || record.companyAddress || record.reservations || record.contactPerson,
      errors: {
        email: '',
        firstname: '',
        lastname: '',
        examination: ''
      },
      sysMessage: buildSysMessage(record)
    }

  }

  checkField (fieldName, value, error) {
    let otherFields = null

    switch (fieldName) {
      case 'email':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_BOOKING_EMAILEMPTY')
        } else if (!value.match(/^[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+(\.[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-zäöüßÄÖÜ0-9][A-Za-zäöüßÄÖÜ0-9-]*\.)+[A-Za-zäöüßÄÖÜ0-9]{2,}$/)) {
          error = getString('ERROR_BOOKING_EMAILWRONG')
        } else {
          let actRecord = Object.assign({}, this.state['actRecord'])
          let [firstname, lastname] = namesFromEmail(value)
          let [oldFirstname, oldLastname] = namesFromEmail(actRecord.email)
          if (firstname) {
            if (actRecord.firstname === '' || (oldFirstname && actRecord.firstname === oldFirstname)) {
              if (otherFields === null) otherFields = {}
              otherFields.firstname = firstname
            }
          }
          if (lastname) {
            if (actRecord.lastname === '' || (oldLastname && actRecord.lastname === oldLastname)) {
              if (otherFields === null) otherFields = {}
              otherFields.lastname = lastname
            }
          }
        }
        break

      case 'firstname':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^[A-Za-zÄÖÜäöüß]\S+(\s[A-Za-zÄÖÜäöüß]\S+)*$/)) {
            error = getString('ERROR_BOOKING_INVALIDFIRSTNAME')
          }
        }
        break

      case 'lastname':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^[A-Za-zÄÖÜäöüß]\S+(\s[A-Za-zÄÖÜäöüß]\S+)*$/)) {
            error = getString('ERROR_BOOKING_INVALIDLASTNAME')
          }
        }
        break

      case 'examination':
        if (typeof value === 'undefined' || value === null) value = null
        if (value === null) {
          error = getString('ERROR_BOOKING_EXAMINATIONEMPTY')
        } else {
          if (!EXAMINATIONS.find(element => element.courseName === value.courseName && element.examinationDate === value.examinationDate)) {
            error = getString('ERROR_BOOKING_INVALIDEXAMINATION')
          } else {
            otherFields = { courseName: value.courseName, examinationDate: value.examinationDate }
          }
        }
        break

      default:
    }

    return Object.assign({
      sanitizedValue: value,
      error: error
    }, (otherFields !== null ? { otherFields: otherFields } : {}))
  }

  changeField (fieldName, value) {
    const { actRecord, errors } = this.state
    const newRecord = Object.assign({}, actRecord)
    const newErrors = Object.assign({}, errors)
    const changeSet = {}

    let checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave (event) {
    event.preventDefault()

    const { isSubmitting, readOnly } = this.state
    if (!isSubmitting) {
      if (readOnly) {
        const { closeFunc } = this.props
        closeFunc(event)
        return
      } else {
        this.setState({ isSubmitting: true }, () => {this.internalHandleSave()})
      }
    }
  }

  async internalHandleSave () {
    await timeout(200)

    // check all checkable fields again
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }

    if (!hasErrors) {
      const { saveFunc } = this.props
      let sendResult = await saveFunc(newRecord)
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        enhanceExamination(sendResult)
        this.setState({
          actRecord: sendResult,
          readOnly: Boolean(sendResult.onetimeToken),
          choosenTab: sendResult.onetimeToken ? 1 : 0,
          sysMessage: buildSysMessage(sendResult),
          isSubmitting: false
        })
      }
    } else {
      console.log('ERROR', 'BookingDialog.internalHandleSave', newErrors)
      this.setState({ errors: newErrors, isSubmitting: false })
    }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ choosenTab: newValue })
  }

  renderTabs () {
    const { classes } = this.props
    const { actRecord, hasDetails } = this.state

    const tabs = []

    tabs.push(<Tab label={getString('BOOKING_TAB_USER')} id="tab_user" key="tab_user"
                   aria-controls="tab_panel_user" classes={{ selected: classes.tabSelected }}/>)

    if (actRecord.onetimeToken) {
      tabs.push(<Tab label={getString('BOOKING_TAB_TOKEN')} id="tab_token" key="tab_token"
                     aria-controls="tab_panel_token"
                     classes={{ selected: classes.tabSelected }}/>)
    }

    if (hasDetails) {
      tabs.push(<Tab label={getString('BOOKING_TAB_DETAILS')} id="tab_details" key="tab_details"
                     aria-controls="tab_panel_details"
                     classes={{ selected: classes.tabSelected }}/>)
    }

    return tabs
  }

  renderPanels () {
    const { classes } = this.props
    const { sysMessage, actRecord, errors, choosenTab, hasDetails, readOnly } = this.state

    const panels = []
    let actIndex = 0

    panels.push(<OptionsPanel value={choosenTab} index={actIndex} dir={'ltr'} id="tab_panel_user" key={actIndex}>
      <TextField
        id="email"
        label={getString('BOOKING_LABEL_EMAIL')}
        required={true}
        type="text"
        error={errors.email !== ''}
        helperText={errors.email}
        onChange={(event) => {this.changeField('email', event.target.value)}}
        value={actRecord.email}
        className={classes.formControl}
        style={{ flex: '1 0 98%' }}
        readOnly={readOnly}
        disabled={readOnly}
      />

      <TextField
        id="firstname"
        label={getString('BOOKING_LABEL_FIRSTNAME')}
        required={true}
        type="text"
        error={errors.firstname !== ''}
        helperText={errors.firstname}
        onChange={(event) => {this.changeField('firstname', event.target.value)}}
        value={actRecord.firstname}
        className={classes.formControl}
        style={{ flex: '1 0 45%' }}
        readOnly={readOnly}
        disabled={readOnly}
      />

      <TextField
        id="lastname"
        label={getString('BOOKING_LABEL_LASTNAME')}
        required={true}
        type="text"
        error={errors.lastname !== ''}
        helperText={errors.lastname}
        onChange={(event) => {this.changeField('lastname', event.target.value)}}
        value={actRecord.lastname}
        className={classes.formControl}
        style={{ flex: '1 0 45%' }}
        readOnly={readOnly}
        disabled={readOnly}
      />

      <Autocomplete
        id="examination"
        includeInputInList={true}
        autoSelect={true}
        disableClearable={true}
        options={EXAMINATIONS}
        value={actRecord.examination}
        onChange={(event, newInputValue) => {this.changeField('examination', newInputValue)}}
        getOptionLabel={(examination) => { return examination.courseName + ' ' + formatDate(examination.examinationDate)}}
        getOptionSelected={(option, value) => {return value ? (option.courseName === value.courseName && option.examinationDate === value.examinationDate) : false }}
        renderInput={(params) => <TextField {...params} required label={getString('BOOKING_LABEL_COURSE')}
                                            readOnly={readOnly}/>}
        className={classes.formControl}
        fullWidth={true}
        disabled={readOnly}
      />

      <TextField
        id="dateCreated"
        label={getString('BOOKING_LABEL_DATECREATED')}
        type="text"
        value={formatDateTime(actRecord.dateCreated)}
        className={classes.formControl}
        style={{ flex: '1 0 45%' }}
        readOnly={true}
        disabled={true}
      />

      <TextField
        id="lastUpdated"
        label={getString('BOOKING_LABEL_LASTUDATED')}
        type="text"
        value={formatDateTime(actRecord.lastUpdated)}
        className={classes.formControl}
        style={{ flex: '1 0 45%' }}
        readOnly={true}
        disabled={true}
      />
    </OptionsPanel>)

    if (actRecord.onetimeToken) {
      actIndex++

      panels.push(<OptionsPanel value={choosenTab} index={actIndex} dir={'ltr'} id="tab_panel_token" key={actIndex}>
        <TextField
          id="onetimeToken"
          label={getString('BOOKING_LABEL_ONETIMEOKEN')}
          type="text"
          value={actRecord.onetimeToken}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <QRCode content={JSON.stringify(sysMessage)}
                filename={'qr-' + actRecord.onetimeToken}
                title={''}
                id={''}
                logoConfig={null}
                type={'sysMessage'}
        />
        <TextField
          id="registered"
          label={getString('BOOKING_LABEL_REGISTERED')}
          type="text"
          value={actRecord.isUser ? 'Nutzer hat sich an der Plattform bereits angemeldet' : 'Muss sich noch bei vme-learning.de anmelden.'}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <TextField
          id="acceptedDate"
          label={getString('BOOKING_LABEL_ACCEPTEDDATE')}
          type="text"
          value={formatDateTime(actRecord.acceptedDate)}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
      </OptionsPanel>)
    }

    if (hasDetails) {
      actIndex++

      panels.push(<OptionsPanel value={choosenTab} index={actIndex} dir={'ltr'} id="tab_panel_details" key={actIndex}>
        <TextField
          id="comment"
          label={getString('BOOKING_LABEL_COMMENT')}
          type="text"
          multiline={true}
          rowsMax={3}
          value={actRecord.comment}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <TextField
          id="company"
          label={getString('BOOKING_LABEL_COMPANY')}
          type="text"
          value={actRecord.company}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <TextField
          id="companyAddress"
          label={getString('BOOKING_LABEL_COMPANYADDRESS')}
          type="text"
          multiline={true}
          rowsMax={3}
          value={actRecord.companyAddress}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <TextField
          id="reservations"
          label={getString('BOOKING_LABEL_RESERVATIONS')}
          type="text"
          multiline={true}
          rowsMax={3}
          value={actRecord.reservations}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
        <TextField
          id="contactPerson"
          label={getString('BOOKING_LABEL_CONTACTPERSON')}
          type="text"
          multiline={true}
          rowsMax={3}
          value={actRecord.contactPerson}
          className={classes.formControl}
          style={{ flex: '1 0 98%' }}
          readOnly={true}
          disabled={true}
        />
      </OptionsPanel>)
    }

    return panels
  }

  render () {
    const { classes, action, title, closeFunc } = this.props
    const { isSubmitting, choosenTab, readOnly } = this.state

    const actionText = readOnly ? getString('BACK_BUTTON') : action
    const titleText = readOnly ? getString('BOOKING_SHOW_HEADLINE') : title

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting}/>

        <form onSubmit={(event) => {this.handleSave(event)}}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{titleText}</DialogTitle>

          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'stretch',
              paddingBottom: readOnly ? 16 : 80
            }}
            dividers={true}>

            <div style={{ flex: '1 0 100%', marginTop: 16 }}>
              <Tabs
                value={choosenTab}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="play mode options"
              >
                {this.renderTabs()}
              </Tabs>
            </div>

            <SwipeableViews
              axis={'x'}
              index={choosenTab}
              onChangeIndex={this.handleChangeTab}
              style={{ width: '100%', border: '1px solid rgba(0,0,0,0.26)' }}
            >
              {this.renderPanels()}
            </SwipeableViews>
          </DialogContent>

          <DialogActions className={classes.footLine}>
            {!readOnly && <CancelButton onClick={closeFunc}/>}
            <SubmitButton title={actionText} disabled={false}/>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

BookingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
}

export default withStyles(styles)(BookingDialog)
