import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'
import getString from '../config/strings'
import { validResolutionsForApi } from '../config/resolutions'

const useStyles = makeStyles({
  formControl: {
    margin: 0,
    minWidth: 120
  }
})

export const LinkSelector = memo(({ label, match, fieldName, selection }) => {
  const classes = useStyles()
  const history = useHistory()

  const linkValues = Object.keys(selection)
  const actValue = match.params[fieldName]

  const newPath = (value) => {
    const params = Object.assign({}, match.params)
    params[fieldName] = value
    return generatePath(match.path, params)
  }

  if (linkValues.length === 0) return null

  if (linkValues.indexOf(match.params[fieldName]) === -1) {
    history.replace(newPath(linkValues[0]))
    return null
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor="resolutionfield">
        {label}
      </InputLabel>
      <Select
        value={match.params[fieldName]}
        onChange={(e) => {}}
        input={<Input name="contentTag" id="contentTagField" />}
        name="contentTag"
        style={{ textAlign: 'left' }}
      >
        {linkValues.map((value) => (
          <MenuItem key={value} value={value} dense>
            <Link
              component={RouterLink}
              color={'textPrimary'}
              to={newPath(value)}
              style={{ fontWeight: value === actValue ? 500 : 400, flexGrow: 1 }}
            >
              {selection[value]}
            </Link>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

LinkSelector.propTypes = {
  label: PropTypes.string,
  match: PropTypes.exact({
    path: PropTypes.string.isRequired, // the path used to match
    url: PropTypes.string.isRequired, // the matched portion of the URL
    isExact: PropTypes.bool.isRequired, // whether or not we matched exactly
    params: PropTypes.objectOf(PropTypes.string)
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  selection: PropTypes.objectOf(PropTypes.string).isRequired
}

LinkSelector.defaultProps = {
  label: 'Applikation'
}

export const ResolutionSelector = memo(({ match, excludes }) => {
  const resolutionSelection = validResolutionsForApi.reduce((prev, act) => {
    if (!excludes.includes(act.name)) prev[act.name] = getString(act.optionName)
    return prev
  }, {})

  return (
    <LinkSelector
      label={getString('LABEL_TIME_RESOLUTION')}
      fieldName="resolution"
      match={match}
      selection={resolutionSelection}
    />
  )
})

ResolutionSelector.propTypes = {
  match: PropTypes.exact({
    path: PropTypes.string.isRequired, // the path used to match
    url: PropTypes.string.isRequired, // the matched portion of the URL
    isExact: PropTypes.bool.isRequired, // whether or not we matched exactly
    params: PropTypes.objectOf(PropTypes.string)
  }).isRequired,
  excludes: PropTypes.arrayOf(PropTypes.string).isRequired
}

ResolutionSelector.defaultProps = {
  excludes: []
}
