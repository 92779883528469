import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import DrillDownChart from '../charts/DrillDownChart'
import ApiService from '../../services/APIService'
import { validateResolutionFromProps } from '../../config/resolutions'
import { ResolutionSelector } from '../LinkSelector'
import { seriesToTable, sortDate } from '../utils/helperFunctions'
import DataTable from '../library/tableComponents/DataTable'
import { refreshAuth } from './helpers/pageHelper'

class ErvVisitsPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      seriesBase: {},
      headLine: [],
      dataLines: [],
      fetchDate: '',
      resolution: validateResolutionFromProps(props)
    }
  }

  componentDidMount() {
    this.updateStats()
  }

  static getDerivedStateFromProps(props, state) {
    const resolution = validateResolutionFromProps(props)

    if (resolution.name !== state.resolution.name) {
      return { resolution: resolution, isLoading: true }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevState.resolution.name !== this.state.resolution.name) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {
        tabActions.updateIndicator()
      }, 0)
    }
  }

  filterCountries(sortedCountries, auth) {
    if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.countries) {
      let allowedCountries = auth.user.additionalData.countries
      if (!Array.isArray(allowedCountries)) allowedCountries = [allowedCountries]
      let upperAllowedCountries = allowedCountries.map((country) => country.toString().toUpperCase())
      return sortedCountries.filter(
        (country) => upperAllowedCountries.indexOf(country.toString().toLocaleUpperCase()) >= 0
      )
    } else {
      return sortedCountries
    }
  }

  async updateStats() {
    const { resolution } = this.state

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const data = await ApiService.getErvVisits(newAuth, resolution.apiParam)

      // filter for countries in user
      const countryRows = {}
      let sortedCountries = data.countries
        .map(function (country) {
          const translated = country === '-' ? 'other' : country.toUpperCase()
          countryRows[translated] = []
          return translated
        })
        .sort()

      // filter for countries in user
      sortedCountries = this.filterCountries(sortedCountries, newAuth)

      const hierarchy = {}
      data.lines.forEach((entry) => {
        const date = entry[0]
        const countryMap = entry[1]
        let translatedCategory
        if (typeof date === 'number') {
          translatedCategory = new Date(date)
          if (translatedCategory < new Date(2018, 5, 1)) return
          translatedCategory = translatedCategory.toJSON()
          translatedCategory = translatedCategory.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$3.$2.$1')
        } else {
          if (date < '201806') return
          translatedCategory = date.substring(0, 4) + '-' + date.substring(4)
        }

        if (!hierarchy[translatedCategory]) hierarchy[translatedCategory] = {}

        sortedCountries.forEach((country) => {
          const translatedCountry = country === 'other' ? '-' : country

          if (countryMap[translatedCountry]) {
            hierarchy[translatedCategory][country] = countryMap[translatedCountry].clients || 0
          }
        })
      })

      const seriesBase = {
        data: hierarchy,
        description: {
          0: { title: '', drillUpText: '', order: Object.keys(hierarchy).sort(sortDate), colorByPoint: true },
          1: {
            title: 'Period: {{PLACEHOLDER}}',
            drillUpText: 'periods',
            order: sortedCountries,
            level: 'country',
            type: 'column'
          }
        }
      }

      const headLine = [getString('DATE'), ...sortedCountries, getString('TOTAL')]
      const dataLines = seriesToTable(seriesBase, true)

      this.setState({
        fetchDate: new Date(data.fetchDate).toLocaleString(),
        seriesBase: seriesBase,
        headLine: headLine,
        dataLines: dataLines,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  render() {
    if (this.state.isLoading) {
      return <WaitForLoad />
    } else {
      const { headLine, dataLines, seriesBase, fetchDate } = this.state
      const { match } = this.props
      const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
      const filenamePrefix = 'activeapps'

      return (
        <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
          <Grid container alignItems="center" style={{ padding: 24 }}>
            <Grid item xs={6}>
              {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <ResolutionSelector match={match} excludes={['yearly']} />
            </Grid>
          </Grid>

          {hasSeries && (
            <Grid item xs={12}>
              <DrillDownChart title="" seriesBase={seriesBase} legend={<Legend verticalAlign="top" />} />
            </Grid>
          )}

          <DataTable
            headLine={headLine}
            dataLines={dataLines}
            fetchDate={fetchDate}
            excelTitle={getString('MENU_ERVVISITS')}
            filenamePrefix={filenamePrefix}
          />
        </Grid>
      )
    }
  }
}

ErvVisitsPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(ErvVisitsPage)
