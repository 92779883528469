import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'

import ShowContextPath from './ShowContextPath'
import AudioEngagementChart from './charts/AudioEngagementChart'
import ViewsHistoryChart from './ViewsHistoryChart'
import AudioPlayer from './AudioPlayer'

const AccordionSummary = withStyles({
  expanded: {
    backgroundColor: '#f5f5f5',
    '& p': {
      fontWeight: 'bold'
    }
  }
})(MuiAccordionSummary)

class AudioView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      enhancedTracks: props.audio.audios
    }

    this.engagementSetter = null
    this.audioSetter = null
  }

  updatePosition = (second, initiator) => {
    if (initiator === 'audio' && this.engagementSetter) {
      this.engagementSetter(second)
    }
    if (initiator === 'engagement' && this.audioSetter) {
      this.audioSetter(second)
    }
  }

  storeEngagementSetter = (func) => {
    this.engagementSetter = func
  }

  storeAudioSetter = (func) => {
    this.audioSetter = func
  }

  updateTrackSizes = (tracksWithDuration) => {
    this.setState({ enhancedTracks: tracksWithDuration })
  }

  render() {
    const { audio } = this.props
    const { enhancedTracks } = this.state

    let listenCount = audio.listens && Array.isArray(audio.listens) ? audio.listens.length : 0
    let viewSeconds = 0

    if (listenCount) {
      for (const viewEntry of audio.listens) {
        const sequences = viewEntry.sequences
        if (sequences && Array.isArray(sequences)) {
          for (const sequence of sequences) {
            if ( sequence.start < 0 ) continue
            if ( sequence.end <= 0 ) continue

            const start = Math.trunc(sequence.start)
            const end = Math.trunc(sequence.end)

            if ( start > end ) continue
            viewSeconds += end - start
          }
        }
      }
    }

    return (
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'Audio-' + audio.id + '-content'}
          id={'Audio-' + audio.id + '-header'}
        >
          <Typography variant={'body1'}>
            {audio.title + ' (' + listenCount + ' - ' + (viewSeconds / 60).toFixed(1).replace('.', ',') + 'min.)'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <ShowContextPath nugget={audio} />
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <AudioPlayer
                tracks={audio.audios}
                updatePosition={this.updatePosition}
                returnPointSetter={this.storeAudioSetter}
                updateTrackSizes={this.updateTrackSizes}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <AudioEngagementChart
                listens={audio.listens}
                updatePosition={this.updatePosition}
                returnPointSetter={this.storeEngagementSetter}
                trackDurations={enhancedTracks}
              />
            </Grid>
            <Grid item xs={12}>
              <ViewsHistoryChart views={audio.listens} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
}

AudioView.propTypes = {
  audio: PropTypes.object.isRequired
}

export default AudioView
