import React, { PureComponent, useRef, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import PauseCircleIcon from '@material-ui/icons/PauseCircleFilled'
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilled'

const AudioTrack = memo(({ trackDesc, index, isLoaded, isPlaying, setPlay, setPause, setDuration }) => {
  const audioElementRef = useRef()

  useEffect(() => {
    const audioElement = audioElementRef.current
    if (audioElement) {
      if (audioElement.duration) {
        setDuration(index, audioElement.duration)
      } else {
        audioElement.onloadeddata = (event) => {
          setDuration(index, audioElement.duration)
        }
        audioElement.load()
      }
    }
  }, [])

  const handleClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (isLoaded) {
      if (isPlaying) {
        setPause(index)
      } else {
        setPlay(index)
      }
    } else {
      setPlay(index)
    }
  }

  return (
    <Box style={{ paddingBottom: 8, display: 'flex', flexWrap: 'nowrap' }}>
      <IconButton size="small" onClick={handleClick}>
        {isLoaded && isPlaying && <PauseCircleIcon />}
        {(!isLoaded || !isPlaying) && <PlayCircleIcon />}
      </IconButton>
      <ol start={index + 1} style={{ display: 'inline-flex', flex: '0 1 auto' }}>
        <li style={{ fontWeight: isLoaded ? 700 : 400 }}>{trackDesc.name}</li>
      </ol>
      <audio
        src={trackDesc.url}
        ref={audioElementRef}
        autoPlay={false}
        preload={'metadata'}
        style={{ display: 'none' }}
      />
    </Box>
  )
})

AudioTrack.propTypes = {
  trackDesc: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  setPlay: PropTypes.func.isRequired,
  setPause: PropTypes.func.isRequired,
  setDuration: PropTypes.func.isRequired
}

class AudioPlayer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      actAudioIndex: 0,
      isPlaying: false,
      canPlay: false,
      playPos: undefined
    }
    this.audio = React.createRef()
    this.audioDurations = []
    this.lastPosition = 0
  }

  onTimeUpdate = (event) => {
    let actPosition = Math.trunc(this.audio.current.currentTime)
    if (actPosition !== this.lastPosition) {
      const { actAudioIndex } = this.state
      this.lastPosition = actPosition
      let sum = 0
      for (let index = 0; index < this.audioDurations.length; index += 1) {
        let duration = this.audioDurations[index]
        if (typeof duration === 'undefined') return

        if (index === actAudioIndex) break
        sum += Math.trunc(duration)
      }

      this.props.updatePosition(actPosition + sum, 'audio')
    }
  }

  setTime = (second) => {
    if (this.audio.current) {
      const { tracks } = this.props
      const { actAudioIndex } = this.state

      let sum = 0
      for (let index = 0; index < tracks.length; index += 1) {
        let actDuration = this.audioDurations[index]
        if (typeof actDuration === 'undefined') return

        if (sum + Math.trunc(actDuration) > second) {
          if (index !== actAudioIndex) {
            // set index and time
            this.setState({ actAudioIndex: index, playPos: second - sum })
          } else {
            // only set time
            this.audio.current.currentTime = second - sum
          }
          break
        } else {
          sum += Math.trunc(actDuration)
        }
      }
    }
  }

  componentDidMount() {
    if (this.audio.current) {
      this.audio.current.addEventListener('timeupdate', this.onTimeUpdate)
      this.props.returnPointSetter((second) => {
        this.setTime(second)
      })
    }
  }

  componentWillUnmount() {
    if (this.audio.current) {
      this.audio.current.removeEventListener('timeupdate', this.onTimeUpdate)
    }
  }

  onEnded = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const { actAudioIndex } = this.state
    this.setState({ canPlay: true, isPlaying: false })
    this.setPlay(actAudioIndex + 1)
  }

  onPause = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.setState({ isPlaying: false })
  }

  onPlay = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.setState({ isPlaying: true })
  }

  onCanPlay = (event) => {
    event.stopPropagation()
    event.preventDefault()

    const { canPlay, playPos } = this.state

    if (canPlay) {
      if (this.audio.current) {
        this.audio.current.play()
      }
    }

    if (typeof playPos === 'number') {
      if (this.audio.current) {
        this.audio.current.currentTime = playPos
        this.setState({ playPos: undefined })
      }
    }
  }

  setPlay = (index) => {
    const { tracks } = this.props
    if (index >= 0 && index < tracks.length) {
      const { actAudioIndex } = this.state
      if (index !== actAudioIndex) {
        this.setState({ actAudioIndex: index, canPlay: true, isPlaying: false })
      } else {
        if (this.audio.current) {
          this.audio.current.play()
        }
      }
    }
  }

  setPause = (index) => {
    const { tracks } = this.props
    if (index >= 0 && index < tracks.length) {
      const { actAudioIndex } = this.state
      if (index === actAudioIndex) {
        if (this.audio.current) {
          this.audio.current.pause()
        }
      }
    }
  }

  setDuration = (index, duration) => {
    const { tracks, updateTrackSizes } = this.props
    this.audioDurations[index] = duration

    const result = []
    for (let i = 0; i < tracks.length; i += 1) {
      if (typeof this.audioDurations[i] === 'undefined') return

      const trackDesc = tracks[i]
      result[i] = { ...trackDesc, duration: this.audioDurations[i] }
    }

    updateTrackSizes(result)
  }

  render() {
    const { tracks } = this.props
    const { actAudioIndex, isPlaying } = this.state
    const url = tracks[actAudioIndex].url

    return (
      <React.Fragment>
        <audio
          src={url}
          controls
          onEnded={this.onEnded}
          onCanPlay={this.onCanPlay}
          onPause={this.onPause}
          onPlay={this.onPlay}
          style={{ width: '100%' }}
          ref={this.audio}
        />
        {tracks.map((trackDesc, index) => (
          <AudioTrack
            key={trackDesc.target_id}
            isPlaying={isPlaying}
            trackDesc={trackDesc}
            setPause={this.setPause}
            setPlay={this.setPlay}
            index={index}
            isLoaded={index === actAudioIndex}
            setDuration={this.setDuration}
          />
        ))}
      </React.Fragment>
    )
  }
}

AudioPlayer.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  updatePosition: PropTypes.func.isRequired, // sends the position to parent, which send it to the engagement
  returnPointSetter: PropTypes.func.isRequired, // sends a function to set the time of the audio to the parent
  updateTrackSizes: PropTypes.func.isRequired
}

export default AudioPlayer
