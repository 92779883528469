import { USAGE_FILTER_USER } from '../../config/config'

export function sortDate(a, b) {
  if (a.indexOf('.') >= 0) {
    const aParts = a.split('.')
    const bParts = b.split('.')
    const aDate = new Date(parseInt(aParts[2], 10), parseInt(aParts[1], 10) - 1, parseInt(aParts[0], 10))
    const bDate = new Date(parseInt(bParts[2], 10), parseInt(bParts[1], 10) - 1, parseInt(bParts[0], 10))
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
  } else {
    return a < b ? -1 : a > b ? 1 : 0
  }
}

/**
 *
 * @param logDate {number|string}
 * @return {string}
 */
export const formatDate = (logDate) => {
  if (typeof logDate === 'number') {
    const stringDate = logDate.toString()
    return stringDate.length > 4 ? stringDate.substring(0, 4) + '-' + stringDate.substring(4) : stringDate
  } else if ( typeof logDate === 'string' ) {
    if ( logDate.match(/^(\d{4})-(\d{2})-(\d{2}).*$/) ) {
      return logDate.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$3.$2.$1')
    } else {
      return logDate
    }
  } else {
    throw new Error('invalid logDate ' + logDate)
  }
}

export function dialogConfig(auth, dialogName) {
  return auth &&
    auth.user &&
    auth.user.additionalData &&
    auth.user.additionalData.dialogs &&
    auth.user.additionalData.dialogs[dialogName]
    ? auth.user.additionalData.dialogs[dialogName]
    : null
}

export function noPlatformsFromAuth(auth) {
  return auth && auth.user && auth.user.additionalData && auth.user.additionalData.noPlatforms
    ? auth.user.additionalData.noPlatforms
    : false
}

export function sourceSelectorFromAuth(auth) {
  return auth && auth.user && auth.user.additionalData && auth.user.additionalData.sourceSelector
    ? auth.user.additionalData.sourceSelector
    : false
}

export function contentTagsFromAuth(auth) {
  return auth && auth.user && auth.user.additionalData && auth.user.additionalData.allowedTags
    ? auth.user.additionalData.allowedTags
    : {}
}

export function validateContentTagFromProps(props) {
  if (!props) return null

  const contentTags = Object.keys(contentTagsFromAuth(props.auth))

  if (props.match && props.match.params && props.match.params.contentTag) {
    const contentTag = props.match.params.contentTag

    if (contentTags.indexOf(contentTag) >= 0) {
      return contentTag
    }
  }

  return contentTags.length > 0 ? contentTags[0] : null
}

export function validateSubDomainFromProps(props) {
  if (!props) return '__all'

  if (props.match && props.match.params && props.match.params.subDomain) {
    const subDomain = props.match.params.subDomain

    if (props.hostNames && props.hostNames[subDomain]) {
      return subDomain
    }
  }

  return '__all'
}

function getAllowedCountries(auth) {
  if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.countries) {
    let allowedCountries = auth.user.additionalData.countries
    if (!Array.isArray(allowedCountries)) allowedCountries = [allowedCountries]
    return allowedCountries
  } else {
    return []
  }
}

export function agenciesFromAuth(auth) {
  let allowedAgenciesMap = {}
  if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.allowedAgencies) {
    let allowedAgencies = auth.user.additionalData.allowedAgencies
    if (!Array.isArray(allowedAgencies)) allowedAgencies = [allowedAgencies]
    for (const agency of allowedAgencies) allowedAgenciesMap[agency] = agency
  }
  return allowedAgenciesMap
}

export function hostNamesFromClients(clients, auth) {
  const drupalTag = Object.keys(USAGE_FILTER_USER)[0]
  const isERGOPRO = drupalTag === 'ergopro'
  const isERV = drupalTag === 'erv'
  const isLearningMiniatures = drupalTag === 'lernminiaturen'

  let hostNames = { __all: 'Alle' }
  switch (drupalTag) {
    case 'ergopro':
      {
        const userInfos = clients && clients.userInfo ? clients.userInfo : {}
        for (const userInfo of Object.values(userInfos)) {
          const region = userInfo.region ? userInfo.region : 'none'
          hostNames[region] = region
        }
      }
      break

    case 'schichtarbeit':
      {
        for (const clientInfo of Object.values(clients.clientInfo)) {
          const requestCode = clientInfo.requestCode ? clientInfo.requestCode : 'unknown'
          hostNames[requestCode] = requestCode
        }
      }
      break

    case 'lernminiaturen':
      {
        const userInfos = clients && clients.userInfo ? clients.userInfo : {}
        for (const userInfo of Object.values(userInfos)) {
          const mailDomain = userInfo.mailDomain ? userInfo.mailDomain : 'other'
          hostNames[mailDomain] = mailDomain
        }
      }
      break

    case 'erv':
      {
        const allowedCountries = getAllowedCountries(auth)
        for (const clientInfo of Object.values(clients.clientInfo)) {
          const homeCountry =
            clientInfo && clientInfo.homeCountry
              ? clientInfo.homeCountry === '-'
                ? 'other'
                : clientInfo.homeCountry
              : 'other'
          if (allowedCountries.length === 0 || allowedCountries.indexOf(homeCountry) >= 0)
            hostNames[homeCountry] = homeCountry
        }
      }
      break

    default:
      for (const clientInfo of Object.values(clients.clientInfo)) {
        if (!clientInfo.hostName || clientInfo.hostName === 'unknown') {
          hostNames['__unknown'] = 'unknown'
        } else {
          hostNames[clientInfo.hostName.split(/\./)[0]] = clientInfo.hostName
        }
      }
  }

  let sortedHostNames = {}
  Object.keys(hostNames)
    .sort()
    .forEach((subDomain) => {
      sortedHostNames[subDomain] = hostNames[subDomain]
    })
  return sortedHostNames
}

export const clientsToHostNames = (clientMapping, auth, drupalTag) => {
  const client2HostName = {}

  const { clientInfo = {}, userInfo = {} } = clientMapping
  switch (drupalTag) {
    case 'ergopro':
      {
        for (const [clientId, clientEntry] of Object.entries(clientInfo)) {
          if (!clientId) continue
          if (!clientEntry) continue
          if (clientEntry.user) {
            const user = userInfo[clientEntry.user]
            client2HostName[clientId] = user.region || 'none'
          } else {
            client2HostName[clientId] = 'none'
          }
        }
      }
      break

    case 'schichtarbeit':
      {
        for (const [clientId, clientEntry] of Object.entries(clientInfo)) {
          if (!clientId) continue
          if (!clientEntry) continue
          client2HostName[clientId] = clientEntry.requestCode || 'unknown'
        }
      }
      break

    case 'lernminiaturen':
      {
        for (const [clientId, clientEntry] of Object.entries(clientInfo)) {
          if (!clientId) continue
          if (!clientEntry) continue
          if (clientEntry.user) {
            const user = userInfo[clientEntry.user]
            client2HostName[clientId] = user.mailDomain || 'other'
          } else {
            client2HostName[clientId] = 'other'
          }
        }
      }
      break

    case 'erv':
      {
        const allowedCountries = getAllowedCountries(auth)
        for (const [clientId, clientEntry] of Object.entries(clientInfo)) {
          if (!clientId) continue
          if (!clientEntry) continue
          const homeCountry = (clientInfo.homeCountry || 'other').replace('-', 'other')
          if (allowedCountries.length === 0 || allowedCountries.indexOf(homeCountry) >= 0)
            client2HostName[clientId] = homeCountry
        }
      }
      break

    default:
      for (const [clientId, clientEntry] of Object.entries(clientInfo)) {
        if (!clientId) continue
        if (!clientEntry) continue
        client2HostName[clientId] = clientEntry.hostName || 'unknown'
      }
  }

  return client2HostName
}

export function normalizeTitle(title) {
  return (
    (title
      ? title
          .replace(/&shy;/g, '')
          .replace(/&nbsp;/g, ' ')
          .replace(/<[^>]+>/g, ' ')
      : null) || 'unknown'
  )
}

export function seriesHelper(baseData, dateExtractor, tagExtractor, tagTranslator, tagValue, collectTagTotals) {
  let tagTotals = collectTagTotals ? {} : null
  let tagNames = {}
  let data = {}

  baseData.forEach((entry) => {
    const date = dateExtractor(entry)
    let userTags = tagExtractor(entry)

    if (userTags.length > 0) {
      userTags.forEach((contentTag) => {
        let translatedTag = tagTranslator(contentTag)

        if (!tagNames[translatedTag]) tagNames[translatedTag] = 1

        if (!data[date]) data[date] = {}

        const value = tagValue(entry, contentTag)
        if (!data[date][translatedTag]) data[date][translatedTag] = value
        else data[date][translatedTag] += value

        if (collectTagTotals) {
          if (!tagTotals[translatedTag]) tagTotals[translatedTag] = value
          else tagTotals[translatedTag] += value
        }
      })
    }
  })
  return { data: data, tagNames: tagNames, tagTotals: tagTotals }
}

export function seriesToTable(series, collectRowSum) {
  let dataLines = []

  series.description[0].order.forEach((date) => {
    const line = [date]
    let rowSum = 0
    series.description[1].order.forEach((tag) => {
      const visits = series.data[date][tag] || 0
      rowSum += visits
      line.push(visits)
    })
    if (collectRowSum) line.push(rowSum)
    dataLines.push(line)
  })

  return dataLines
}
