import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ShiftWorkProgressTable from '../tables/ShiftWorkProgressTable'
import ApiService from '../../services/APIService'
import { AuthContext } from '../library/pageComponents/AuthContext'
import ExcelIcon from '../icons/ExcelIcon'
import { validateContentTagFromProps, validateSubDomainFromProps } from '../utils/helperFunctions'
import { START_DATE } from '../../config/config'
import { LinkSelector } from '../LinkSelector'
import * as XLSX from 'xlsx-js-style'

const styles = (theme) => ({
  middleBox: {
    textAlign: 'right'
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

const TRANSLATION_COLUMNS = ['sleep', 'stress', 'nutrition', 'contacts', 'motion']
const TRANSLATION_CONTENT_IDS = {
  day1: [9551, 11482, 8657, 14683, 15685],
  day2: [9488, 11491, 8582, 14584, 15619],
  day3: [9416, 11500, 8546, 14488, 15529],
  day4: [9386, 11509, 8315, 14425, 15460],
  day5: [9338, 11518, 8279, 14344, 15406],
  day6: [9305, 11527, 8249, 14266, 15358],
  day7: [9284, 11536, 8210, 14227, 15214],
  day8: [9215, 11542, 8144, 14152, 15118],
  day9: [9125, 11551, 8063, 14116, 15088],
  day10: [9056, 11560, 7988, 14056, 14977],
  day11: [9014, 11569, 7916, 13993, 14914],
  day12: [9002, 11578, 7799, 13924, 14836],
  day13: [8990, 11587, 7838, 13876, 14776],
  day14: [7749, 11596, 7754, 13513, 13516]
}
const LIKERT_CONTENT_IDS = {
  begin: [23441, 23473, 23447, 23465, 23458],
  end: [23446, 23477, 23454, 23469, 23462]
}

/*
interface SurveyAnswer {
    date: Date
    answers: string
}

interface TopicEntry {
    minDate: Date
    maxDate: Date
    days: Set<int>
    begin: SurveyAnswer
    end: SurveyAnswer
}
*/

class ShiftWorkProgressPage extends PureComponent {
  constructor(props) {
    super(props)

    const headLine = [
      getString('HEAD_LINE_CLIENT'),
      getString('ACTIVATION_CODE_HEADER_CODE'),
      getString('HEAD_LINE_START_DATE')
    ]
    for (const topic of TRANSLATION_COLUMNS) {
      headLine.push(getString('HEAD_LINE_' + topic.toUpperCase() + '_BEGIN_ANSWERS'))
      headLine.push(getString('HEAD_LINE_' + topic.toUpperCase() + '_DAYS'))
      headLine.push(getString('HEAD_LINE_' + topic.toUpperCase() + '_END_ANSWERS'))
    }
    this.state = {
      contentTag: validateContentTagFromProps(props),
      isLoading: false,
      headLine: headLine,
      dataLines: [],
      fetchDate: '',
      subDomain: validateSubDomainFromProps(props),
      hostNames: Object.assign({}, props.hostNames)
    }
  }

  componentDidMount() {
    this.updateStats()
  }

  static getDerivedStateFromProps(props, state) {
    const subDomain = validateSubDomainFromProps(props)
    const propsNames = props.hostNames ? Object.keys(props.hostNames) : []
    const stateNames = state.hostNames ? state.hostNames : {}
    const hostNamesChanged =
      propsNames.length !== Object.keys(stateNames).length || !propsNames.every((x) => stateNames.hasOwnProperty(x))

    if (subDomain !== state.subDomain || hostNamesChanged) {
      const changeSet = {}
      if (subDomain !== state.subDomain) changeSet.subDomain = subDomain
      if (hostNamesChanged) changeSet.hostNames = props.hostNames
      return changeSet
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { subDomain } = this.state
    if (prevState.subDomain !== subDomain) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {
        tabActions.updateIndicator()
      }, 0)
    }
  }

  async updateStats() {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }

      const { contentTag, subDomain } = this.state
      this.setState({ isLoading: true })

      const topicMap = new Map()
      const dayMap = new Map()
      for (const [day, contentIds] of Object.entries(TRANSLATION_CONTENT_IDS)) {
        contentIds.forEach((contentId, topicIndex) => {
          dayMap.set(contentId, day)
          topicMap.set(contentId, TRANSLATION_COLUMNS[topicIndex])
        })
      }

      const likertTopicMap = new Map()
      const likertTimeMap = new Map()
      for (const [timeEntry, contentIds] of Object.entries(LIKERT_CONTENT_IDS)) {
        contentIds.forEach((contentId, topicIndex) => {
          likertTimeMap.set(contentId, timeEntry)
          likertTopicMap.set(contentId, TRANSLATION_COLUMNS[topicIndex])
        })
      }

      const { clientInfo } = await ApiService.getClients(newAuth, [contentTag])
      const usages = await ApiService.getUsages(newAuth, 'day', contentTag, START_DATE)
      const likertAnswers = await ApiService.getLikertResults(newAuth)

      const clientMap = new Map()

      for (const line of usages.lines) {
        const tileNumber = Number.parseInt(line.cId, 10)

        const topic = topicMap.get(tileNumber)
        if (!topic) continue
        const day = dayMap.get(tileNumber)
        if (!day) continue
        const dayNumber = Number.parseInt(day.substring(3), 10)

        const requestCode = clientInfo[line.client]?.requestCode || 'unknown'
        if (subDomain !== '__all' && subDomain !== requestCode) continue

        const lineDate = new Date(line.ld)

        const clientEntry = clientMap.get(line.client)
        if (clientEntry) {
          const topicEntry = clientEntry[topic]
          if (topicEntry) {
            if (!topicEntry.minDate || lineDate < topicEntry.minDate) topicEntry.minDate = lineDate
            if (!topicEntry.maxDate || lineDate > topicEntry.maxDate) topicEntry.maxDate = lineDate
            topicEntry.days.add(dayNumber)
          } else {
            clientEntry[topic] = {
              minDate: lineDate,
              maxDate: lineDate,
              days: new Set([dayNumber]),
              begin: undefined,
              end: undefined
            }
          }
        } else {
          clientMap.set(line.client, {
            [topic]: {
              minDate: lineDate,
              maxDate: lineDate,
              days: new Set([dayNumber]),
              begin: undefined,
              end: undefined
            }
          })
        }
      }

      for (const line of likertAnswers.lines) {
        const nuggetNumber = Number.parseInt(line.nuggetId, 10)

        const topic = likertTopicMap.get(nuggetNumber)
        if (!topic) continue
        const timeEntry = likertTimeMap.get(nuggetNumber)
        if (!timeEntry) continue

        const requestCode = clientInfo[line.client]?.requestCode || 'unknown'
        if (subDomain !== '__all' && subDomain !== requestCode) continue

        const lineResult = {
          date: new Date(line.utc),
          result: line.selectedIds
            .sort((a, b) => (a.index < b.index ? -1 : a.index > b.index ? 1 : 0))
            .map((answer) => answer.value.toLocaleString('de-DE'))
            .join(';')
        }

        const clientEntry = clientMap.get(line.client)
        if (clientEntry) {
          if (lineResult.date < clientEntry.minDate) clientEntry.minDate = lineResult.date

          const topicEntry = clientEntry[topic]
          if (topicEntry) {
            topicEntry[timeEntry] = lineResult
          } else {
            clientEntry[topic] = {
              minDate: lineResult.date,
              maxDate: undefined,
              days: new Set(),
              [timeEntry]: lineResult
            }
          }
        } else {
          clientMap.set(line.client, {
            [topic]: {
              minDate: lineResult.date,
              maxDate: undefined,
              days: new Set(),
              [timeEntry]: lineResult
            }
          })
        }
      }

      // client startDate  sleep(beginanswers, begindate, daysreached, maxDate, endanswers, enddate) stress(...) nutrition(...)
      const resultLines = []
      for (const [clientId, clientEntry] of clientMap.entries()) {
        const requestCode = clientInfo[clientId]?.requestCode || 'unknown'
        const line = [clientId, requestCode]
        let minDate = null
        let hasTopic = false
        TRANSLATION_COLUMNS.forEach((topic, topicIndex) => {
          const topicEntry = clientEntry[topic]
          if (!topicEntry) {
            line[3 + topicIndex * 3] = ''
            line[3 + topicIndex * 3 + 1] = ''
            line[3 + topicIndex * 3 + 2] = ''
          } else {
            if (topicEntry.begin) {
              hasTopic = true
              line[3 + topicIndex * 3] = {
                value: topicEntry.begin.result,
                tooltip: topicEntry.begin.date
              }
            } else {
              line[3 + topicIndex * 3] = ''
            }
            if (topicEntry.days.size) {
              hasTopic = true
              line[3 + topicIndex * 3 + 1] = {
                value: [...topicEntry.days].sort((a, b) => (a < b ? 1 : a > b ? -1 : 0)).join(';'),
                tooltip: topicEntry.maxDate
              }
            } else {
              line[3 + topicIndex * 3 + 1] = ''
            }
            if (topicEntry.end) {
              hasTopic = true
              line[3 + topicIndex * 3 + 2] = {
                value: topicEntry.end.result,
                tooltip: topicEntry.end.date
              }
            } else {
              line[3 + topicIndex * 3 + 2] = ''
            }
            if (!minDate || topicEntry.minDate < minDate) minDate = topicEntry.minDate
          }
        })
        if (hasTopic) {
          line[2] = minDate
          resultLines.push(line)
        }
      }

      this.setState({
        dataLines: resultLines.sort((a, b) => (a[2] < b[2] ? -1 : a[2] > b[2] ? 1 : 0)),
        fetchDate: new Date(usages.fetchDate),
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  handleDownload = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { headLine, dataLines, fetchDate, subDomain } = this.state
    const hasData = dataLines && dataLines.length > 0
    const filenamePrefix = subDomain === '__all' ? 'Schichtbox-Fortschritt' : 'Schichtbox-Fortschritt_' + subDomain
    const filename = filenamePrefix + '-' + new Date().toJSON().substring(0, 10) + '.xlsx'
    if (!hasData) return

    const title = getString('MENU_SHIFT_WORK_PROGRESS')
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
    const columnNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYYZ'
    const columnWidth = headLine.map((_header) => 10)
    columnWidth[0] = title.length * 1.2
    columnWidth[1] = 14 * 1.2

    const rows = []
    rows.push([{ v: title, t: 's', s: { font: { bold: true } } }])
    rows.push([])

    const dateLabel = getString('DATA_COLLECTION_DATE')
    rows.push([
      { v: dateLabel, t: 's' },
      { v: new Date(fetchDate).setMilliseconds(0), t: 'd', z: 'dd.mm.yy hh:mm', s: { font: { bold: true } } }
    ])
    if (dateLabel.length > columnWidth[0]) columnWidth[0] = dateLabel.length
    rows.push([])

    const rightBorder = { border: { right: { style: 'medium', color: { rgb: '#000000' } } } }
    const style = {
      alignment: { horizontal: 'center' },
      font: { bold: true }
    }
    rows.push([
      { v: '', t: 's' },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder },
      { v: 'Schlaf', t: 's', s: style },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder },
      { v: 'Stress', t: 's', s: style },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder },
      { v: 'Ernährung', t: 's', s: style },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder },
      { v: 'Soziale Kontakte', t: 's', s: style },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder },
      { v: 'Bewegung', t: 's', s: style },
      { v: '', t: 's' },
      { v: '', t: 's', s: rightBorder }
    ])

    const headerStyle = {
      alignment: { horizontal: 'center' },
      font: { bold: true },
      border: { bottom: { style: 'medium', color: { rgb: '#000000' } } }
    }
    const alternateHeadLine = [
      'App',
      'Activationcode',
      'erste Nutzung',
      'vorher',
      'Tage',
      'danach',
      'vorher',
      'Tage',
      'danach',
      'vorher',
      'Tage',
      'danach',
      'vorher',
      'Tage',
      'danach',
      'vorher',
      'Tage',
      'danach'
    ]
    rows.push(
      alternateHeadLine.map((header, columnNumber) => {
        if (!columnWidth[columnNumber] || header.length > columnWidth[columnNumber])
          columnWidth[columnNumber] = header.length * 1.2
        const cell = { v: header, t: 's' }
        cell.s = JSON.parse(JSON.stringify(headerStyle))
        if (columnNumber % 3 === 2) {
          cell.s.border.right = rightBorder.border.right
        }
        return cell
      })
    )

    for (const dataLine of dataLines) {
      const row = []
      const rowNumber = rows.length + 1
      for (let columnNumber = 0; columnNumber < dataLine.length; columnNumber += 1) {
        const value = dataLine[columnNumber]
        const cell = { v: '', t: 's' }
        if (value === null || typeof value === 'undefined') {
        } else if (value instanceof Date) {
          value.setMilliseconds(0)
          cell.v = value
          cell.t = 'd'
          cell.z = 'dd.mm.yy hh:mm'
          if (!columnWidth[columnNumber] || 14 > columnWidth[columnNumber]) columnWidth[columnNumber] = 14
        } else if (typeof value === 'string') {
          cell.v = value
          if (!columnWidth[columnNumber] || value.length > columnWidth[columnNumber])
            columnWidth[columnNumber] = value.length
        } else if (typeof value === 'number') {
          cell.v = value
          cell.t = 'n'
          if (!columnWidth[columnNumber] || String(value).length > columnWidth[columnNumber])
            columnWidth[columnNumber] = String(value).length
        } else if (typeof value === 'object') {
          cell.v = String(value.value)
          if (value.tooltip && value.tooltip instanceof Date) {
            const targetString = '#' + columnNames.charAt(columnNumber) + rowNumber
            const tooltipDate = new Date(value.tooltip)
            tooltipDate.setMilliseconds(0)
            const tooltipString = 'am ' + tooltipDate.toLocaleDateString('de-DE', dateOptions)
            cell.l = { Target: targetString, Tooltip: tooltipString }
          }
          if (!columnWidth[columnNumber] || cell.v.length > columnWidth[columnNumber])
            columnWidth[columnNumber] = cell.v.length
        }
        if (columnNumber > 2) {
          cell.s = { alignment: { horizontal: 'right' } }
        }
        if (columnNumber % 3 === 2) {
          if (cell.s) {
            Object.assign(cell.s, rightBorder)
          } else {
            cell.s = rightBorder
          }
        }
        row.push(cell)
      }
      rows.push(row)
    }

    const workSheet = XLSX.utils.aoa_to_sheet(rows, { cellDates: true })
    workSheet['!cols'] = columnWidth.map((width) => ({ wch: width }))
    workSheet['!merges'] = [
      { s: { c: 0, r: 4 }, e: { c: 2, r: 4 } },
      { s: { c: 3, r: 4 }, e: { c: 5, r: 4 } },
      { s: { c: 6, r: 4 }, e: { c: 8, r: 4 } },
      { s: { c: 9, r: 4 }, e: { c: 11, r: 4 } },
      { s: { c: 12, r: 4 }, e: { c: 14, r: 4 } },
      { s: { c: 15, r: 4 }, e: { c: 17, r: 4 } }
    ]
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'LOGS')

    XLSX.writeFile(workBook, filename, { compression: true, bookType: 'xlsx' })
  }

  renderHeader() {
    const { classes, match } = this.props
    const { fetchDate, hostNames, dataLines } = this.state
    const hasData = dataLines && dataLines.length > 0

    return (
      <Grid container alignItems="center" style={{ padding: 24 }}>
        <Grid item sm={4} xs={6}>
          {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate.toLocaleString('de-DE', { timeZone: 'UTC' })}</b>
        </Grid>
        <Grid item sm={4} xs={6} className={classes.middleBox}>
          <LinkSelector
            label={getString('SELECTOR_LABEL_ORIGIN')}
            match={match}
            fieldName="subDomain"
            selection={hostNames}
          />
        </Grid>
        {hasData && (
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            <IconButton onClick={this.handleDownload}>
              <ExcelIcon style={{ color: '#1f7144' }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  }

  renderBody() {
    const { headLine, dataLines } = this.state
    const hasData = dataLines && dataLines.length > 0

    return !hasData ? null : <ShiftWorkProgressTable headLine={headLine} dataLines={dataLines} />
  }

  render() {
    if (this.state.isLoading) {
      return <WaitForLoad />
    } else {
      return (
        <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
          {this.renderHeader()}
          {this.renderBody()}
        </Grid>
      )
    }
  }
}

ShiftWorkProgressPage.propTypes = {
  classes: PropTypes.object.isRequired,
  tabActions: PropTypes.object,
  hostNames: PropTypes.object.isRequired
}

ShiftWorkProgressPage.contextType = AuthContext

export default withRouter(withStyles(styles)(ShiftWorkProgressPage))
