export const validResolutionsForApi = [
    { name: 'daily', apiParam: 'day', chartName: 'Tag', optionName: 'OPTION_DAYLY' },
    { name: 'weekly', apiParam: 'week', chartName: 'Woche', optionName: 'OPTION_WEEKLY' },
    { name: 'monthly', apiParam: 'month', chartName: 'Monat', optionName: 'OPTION_MONTHLY' },
    { name: 'yearly', apiParam: 'year', chartName: 'Jahr', optionName: 'OPTION_YEARLY' },
]
export const getResolutionData = resolution => {
    return validResolutionsForApi.find(validResolution => resolution === validResolution.name)
}
export const validResolutions = validResolutionsForApi.map(resolution => resolution.name)
export const getDefaultResolution = getResolutionData('monthly')
export const validateResolutionFromProps = props => {
    if (props &&
        props.match &&
        props.match.params &&
        props.match.params.resolution
    ) {
        const resolution = props.match.params.resolution

        if (validResolutions.indexOf(resolution) >= 0) {
            return getResolutionData(resolution)
        }
    }

    return getDefaultResolution
}
