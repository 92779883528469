import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import DrillDownChart, { stackLabelSum } from '../charts/DrillDownChart'
import ApiService from '../../services/APIService'
import DataTable from '../library/tableComponents/DataTable'
import { validateResolutionFromProps } from '../../config/resolutions'
import {
  sortDate,
  formatDate,
  contentTagsFromAuth,
  noPlatformsFromAuth,
  sourceSelectorFromAuth,
  validateSubDomainFromProps,
  seriesHelper,
  seriesToTable
} from '../utils/helperFunctions'
import { USAGE_FILTER_USER, DEFAULT_CLIENT_TYPE, DEFAULT_TAG_LIST } from '../../config/config'
import { LinkSelector, ResolutionSelector } from '../LinkSelector'
import { refreshAuth } from './helpers/pageHelper'

const styles = (theme) => ({
  middleBox: {
    textAlign: 'right'
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

class VisitsPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      seriesBase: {},
      headLine: [],
      dataLines: [],
      fetchDate: '',
      subDomain: validateSubDomainFromProps(props),
      resolution: validateResolutionFromProps(props)
    }
  }

  componentDidMount() {
    this.updateStats()
  }

  static getDerivedStateFromProps(props, state) {
    const resolution = validateResolutionFromProps(props)
    const subDomain = validateSubDomainFromProps(props)

    if (resolution.name !== state.resolution.name || subDomain !== state.subDomain) {
      return { resolution: resolution, subDomain: subDomain, isLoading: true }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { resolution, subDomain } = this.state
    if (prevState.resolution.name !== resolution.name || prevState.subDomain !== subDomain) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {
        tabActions.updateIndicator()
      }, 0)
    }
  }

  filterCountries(sortedCountries, auth) {
    if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.countries) {
      let allowedCountries = auth.user.additionalData.countries
      if (!Array.isArray(allowedCountries)) allowedCountries = [allowedCountries]
      let upperAllowedCountries = allowedCountries.map((country) => country.toString().toUpperCase())
      return sortedCountries.filter(
        (country) => upperAllowedCountries.indexOf(country.toString().toLocaleUpperCase()) >= 0
      )
    } else {
      return sortedCountries
    }
  }

  static extractSeries(logLines, client2Type, client2User, seen, filteredCountries, newAuth, drupalTag) {
    switch (drupalTag) {
      case 'lernminiaturen':
      case 'ergopro':
        return seriesHelper(
          logLines,
          (entry) => formatDate(entry['ld']),
          (entry) => [client2Type[entry['client']] || 'none'],
          (contentTag) => contentTag,
          (entry, contentTag) => {
            const date = formatDate(entry['ld'])
            const clientId = entry['client']
            const user = client2User[clientId] || null
            if (!user) return 0

            const selector = date + user + contentTag
            if (seen.has(selector)) {
              return 0
            } else {
              seen.add(selector)
              return 1
            }
          },
          false
        )

      case 'erv':
        return seriesHelper(
          logLines,
          (entry) => formatDate(entry['ld']),
          (entry) => [client2Type[entry['client']] || 'other'],
          (contentTag) => contentTag,
          (entry, contentTag) => {
            const date = formatDate(entry['ld'])
            const clientId = entry['client']
            if (!filteredCountries.has(contentTag)) return 0

            const selector = date + clientId + contentTag
            if (seen.has(selector)) {
              return 0
            } else {
              seen.add(selector)
              return 1
            }
          },
          false
        )

      default:
        return seriesHelper(
          logLines,
          (entry) => formatDate(entry['ld']),
          (entry) => [noPlatformsFromAuth(newAuth) ? DEFAULT_CLIENT_TYPE : client2Type[entry['client']] || 'unknown'],
          (contentTag) => contentTag,
          (entry, contentTag) => {
            const date = formatDate(entry['ld'])
            const clientId = entry['client']
            const user = client2User[clientId] || null
            if (USAGE_FILTER_USER[entry['tag']] && !user) return 0

            const selector = date + (user || clientId)
            if (seen.has(selector)) {
              return 0
            } else {
              seen.add(selector)
              return 1
            }
          },
          false
        )
    }
  }

  async updateStats() {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const contentTags = contentTagsFromAuth(newAuth)
      const drupalTag = Object.keys(USAGE_FILTER_USER)[0]
      const isERGOPRO = drupalTag === 'ergopro'
      const isERV = drupalTag === 'erv'
      const isLearningMiniatures = drupalTag === 'lernminiaturen'
      const { resolution, subDomain } = this.state
      const apiResult = await ApiService.getVisits(
        newAuth,
        resolution.apiParam,
        Object.keys(contentTags),
        isERV ? new Date('2019-05-01T00:00:00.000Z') : null
      )
      const clientMappings = await ApiService.getClients(newAuth, Object.keys(contentTags))
      const userInfos = clientMappings && clientMappings.userInfo ? clientMappings.userInfo : {}
      const client2User = {}
      const client2Type = {}
      const countriesSet = new Set()

      Object.keys(clientMappings.clientInfo).forEach((clientId) => {
        const clientInfo = clientMappings.clientInfo[clientId]
        if (clientInfo) {
          if (clientInfo.user) client2User[clientId] = clientInfo.user

          if (clientInfo.clientType) {
            client2Type[clientId] = clientInfo.clientType
          }

          if (isERV) {
            client2Type[clientId] = clientInfo.homeCountry ? clientInfo.homeCountry : 'other'
            if (client2Type[clientId] === '-') client2Type[clientId] = 'other'
            countriesSet.add(client2Type[clientId])
          }

          if (isERGOPRO && clientInfo.user) {
            const user = userInfos[clientInfo.user]
            client2Type[clientId] = user.region ? user.region : 'none'
          }

          if (isLearningMiniatures && clientInfo.user) {
            const user = userInfos[clientInfo.user]
            client2Type[clientId] = user.mailDomain ? user.mailDomain : 'other'
          }
        }
      })

      const filteredCountries = new Set(this.filterCountries([...countriesSet], newAuth))

      let logLines = []
      if (sourceSelectorFromAuth(newAuth)) {
        if (subDomain === '__all') {
          logLines = apiResult.lines.slice()
        } else {
          const client2HostName = {}
          const fieldName = drupalTag === 'schichtarbeit' ? 'requestCode' : 'hostName'
          for (const clientId of Object.keys(clientMappings.clientInfo)) {
            const clientInfo = clientMappings.clientInfo[clientId]
            client2HostName[clientId] = clientInfo && clientInfo[fieldName] ? clientInfo[fieldName] : 'unknown'
          }

          for (const item of apiResult.lines) {
            let hostName = client2HostName[item.client] || 'unknown'
            if (hostName.split(/\./)[0] === subDomain) {
              logLines.push(item)
            }
          }
        }
      } else {
        logLines = apiResult.lines.slice()
      }

      let seen = new Set()
      const { data, tagNames } =
        Object.keys(contentTags).length > 1
          ? seriesHelper(
              logLines,
              (entry) => formatDate(entry['ld']),
              (entry) => (!entry['tag'] ? DEFAULT_TAG_LIST : [entry['tag']]),
              (contentTag) => contentTags[contentTag],
              (entry, contentTag) => {
                const date = formatDate(entry['ld'])
                const clientId = entry['client']
                const user = client2User[clientId] || null
                if (USAGE_FILTER_USER[entry['tag']] && !user) return 0

                const selector = date + (user || clientId) + entry['tag']
                if (seen.has(selector)) {
                  return 0
                } else {
                  seen.add(selector)
                  return 1
                }
              },
              false
            )
          : VisitsPage.extractSeries(logLines, client2Type, client2User, seen, filteredCountries, newAuth, drupalTag)

      const yOrder = isERV ? this.filterCountries(Object.keys(tagNames), newAuth).sort() : Object.keys(tagNames).sort()

      const seriesBase = {
        data: data,
        description: {
          0: { title: '', drillUpText: '', order: Object.keys(data).sort(sortDate), colorByPoint: true },
          1: {
            title: 'Period: {{PLACEHOLDER}}',
            drillUpText: 'periods',
            order: yOrder,
            level: 'contentTag',
            type: 'column'
          }
        }
      }

      const dataLines = seriesToTable(seriesBase, true)

      this.setState({
        seriesBase: seriesBase,
        headLine: [getString('DATE'), ...seriesBase.description[1].order, getString('TOTAL')],
        dataLines: dataLines,
        fetchDate: new Date(apiResult.fetchDate).toLocaleString(),
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader() {
    const { fetchDate } = this.state
    const { auth, classes, hostNames, match } = this.props
    const sourceSelector = sourceSelectorFromAuth(auth)

    if (!sourceSelector) {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <ResolutionSelector match={match} />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item sm={4} xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.middleBox}>
            <LinkSelector
              fieldName="subDomain"
              label={getString('SELECTOR_LABEL_SUBDOMAIN')}
              match={match}
              selection={hostNames}
            />
          </Grid>
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            <ResolutionSelector match={match} />
          </Grid>
        </Grid>
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return <WaitForLoad />
    } else {
      const { seriesBase, headLine, dataLines, resolution, fetchDate } = this.state
      const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
      const contentTags = contentTagsFromAuth(this.props.auth)
      const filenamePrefix = (Object.keys(contentTags).length > 1 ? 'Visitors-' : 'Platforms-') + resolution.chartName

      return (
        <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
          {this.renderHeader()}

          {hasSeries && (
            <Grid item xs={12}>
              <DrillDownChart
                title=""
                seriesBase={seriesBase}
                stackLabelConfig={stackLabelSum}
                legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120} />}
              />
            </Grid>
          )}

          <DataTable
            headLine={headLine}
            dataLines={dataLines}
            fetchDate={fetchDate}
            excelTitle={getString(Object.keys(contentTags).length > 1 ? 'MENU_VISITS' : 'MENU_PLATFORMS')}
            filenamePrefix={filenamePrefix}
          />
        </Grid>
      )
    }
  }
}

VisitsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  hostNames: PropTypes.object.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(VisitsPage))
