const STRINGS = {
  NOT_IMPLEMENTED: {
    de: 'Diese Funktion ist im Moment noch nicht implementiert.',
    en: 'This function is in the moment not yet implemented.'
  },

  APP_TITLE: {
    de: process.env.REACT_APP_HEADER_TITLE_DE,
    en: process.env.REACT_APP_HEADER_TITLE_EN
  },

  LOGOUT: {
    de: 'Logout',
    en: 'Logout'
  },

  CANCEL: {
    de: 'Abbrechen',
    en: 'Cancel'
  },

  OK: {
    de: 'Ok',
    en: 'Ok'
  },

  YES: {
    de: 'Ja',
    en: 'Yes'
  },

  NO: {
    de: 'Nein',
    en: 'NO'
  },

  DATE_CREATED: {
    de: 'erfasst',
    en: 'created'
  },

  LAST_UPDATED: {
    de: 'letze Änderung',
    en: 'updated'
  },

  // start login component
  LOGIN: {
    de: 'Login',
    en: 'Login'
  },

  EMAIL: {
    de: 'E-Mail-Adresse',
    en: 'Email'
  },

  PASSWORD: {
    de: 'Passwort',
    en: 'Password'
  },

  PASSWORD2: {
    de: 'Passwort Wiederholung',
    en: 'Repeat password'
  },

  REGISTER: {
    de: 'Registrieren',
    en: 'Register'
  },

  FORGOT_TITLE: {
    de: 'Passwort vergessen',
    en: 'Forgot password'
  },

  FORGOT_ERROR: {
    de: 'Passwort vergessen Fehler',
    en: 'Password forgot error'
  },

  FORGOT_SEND: {
    de: 'Passwort zurücksetzen',
    en: 'Reset password'
  },

  FORGOT_SEND_SUCCESS: {
    de: 'Eine Nachricht mit Zurücksetzanweisungen wurde erfolgreich an "$0" verschickt. Sie haben $1 Minuten Zeit, den darin enthaltenen Link zu klicken und ein neues Passwort einzugeben.',
    en: 'A message with reset instructions was successfully sent to "$0". You have $1 minutes to click on the link in the message and enter a new password.'
  },

  ERROR_ACTIVATIONCODE_EMPTY: {
    de: 'Der Activierungscode muss ausgefüllt werden.',
    en: "Activation code can't be empty."
  },

  ERROR_INVALID_EMAIL: {
    de: 'Die Emailadresse scheint nicht korrekt zu sein.',
    en: 'The email address seems to be invalid.'
  },

  ERROR_PASSWORD_SHORT: {
    de: 'Das Passwort ist zu kurz.',
    en: 'The password is too short.'
  },

  ERROR_PASSWORD_POLICY: {
    de: 'Das Passwort entspricht nicht den Anforderungen (Groß-, Kleinbuchstaben, Ziffern, Sonderzeichen).',
    en: "The password does'nt match the requirements. (upper case, lower case letters, digits and special chars)"
  },

  ERROR_PASSWORD_NOTMATCH: {
    de: 'Die Passworte stimmen nicht überein.',
    en: "The passwords doesn't match."
  },

  REGISTRATION_ERROR: {
    de: 'Registrierungsfehler',
    en: 'Registration Error'
  },

  LOGIN_ERROR: {
    de: 'Anmeldefehler',
    en: 'Login Error'
  },

  REGISTRATION_SUCCESS_TITLE: {
    de: 'Registrierung erfolgreich',
    en: 'Registrations succeed'
  },

  REGISTRATION_SUCCESS_TEXT: {
    de: 'Sie haben sich erfoglreich registriert und können die Statistiken einsehen.',
    en: 'You have successfully registered and can start with review statistics.'
  },

  ACTIVATIONCODE: {
    de: 'Activationcode',
    en: 'Activationcode'
  },

  RESET_ERROR: {
    de: 'Passwort ändern Fehler',
    en: 'Change password error'
  },

  RESET_SUCCESS_TEXT: {
    de: 'Sie haben Ihr Passwort erfolgreich geändert und können mit der Arbeit im Admin Backend fortfahren.',
    en: 'You have successfully changed your password and can continue with review statistics.'
  },

  RESET_SUCCESS_TITLE: {
    de: 'Passwortänderung erfolgreich',
    en: 'Password change succeed'
  },

  PASSWORD_RESET: {
    de: 'Neues Passwort eingeben',
    en: 'Insert new password'
  },

  SAVE_PASSWORD: {
    de: 'Speichern',
    en: 'Save'
  },

  'code not found': {
    de: 'Dieser Aktivierungscode ist nicht bekannt.',
    en: 'Activationcode invalid.'
  },

  'user not found': {
    de: 'Nutzer ist nicht bekannt.',
    en: 'User not found.'
  },

  'code in use': {
    de: 'Dieser Aktivierungscode wurde bereits verwendet.',
    en: 'Activationcode is already used.'
  },

  'nickname is used in this group': {
    de: 'Ein Nutzer mit dieser Email wurde bereits registriert.',
    en: 'There is already one User with this email registered.'
  },

  'Bad credentials': {
    de: 'Ungültige Zugangsdaten.',
    en: 'Invalid credentials.'
  },

  // stop login component

  ERROR_HTTP_COMMUNICATION: {
    de: 'Netzwerk Kommunikation nicht erfolgreich',
    en: 'communication error'
  },

  ERROR_HEADLINE: {
    de: 'Fehler',
    en: 'Error'
  },

  MENU_MAILINGS: {
    de: 'Kampagnen',
    en: 'Campaigns'
  },

  MENU_ACTIVATION_CODES: {
    de: 'Activationcodes',
    en: 'Activation codes'
  },

  MENU_MAIL_CAMPAIGNS: {
    de: 'Kampagnen',
    en: 'Campaigns'
  },

  MENU_MAIL_TEMPLATES: {
    de: 'E-Mail Templates',
    en: 'Mailtemplates'
  },

  MENU_MAIL_JOBS: {
    de: 'Versandaufträge',
    en: 'Shipping orders'
  },

  MENU_DNSENTRIES: {
    de: 'DNS Einträge',
    en: 'DNS Entries'
  },

  MENU_REGISTRATION_REQUESTS: {
    de: 'Registrierungsanfragen',
    en: 'Registration Requests'
  },

  MENU_BOOKINGS: {
    de: 'Buchungen',
    en: 'Bookings'
  },

  MENU_USER: {
    de: 'Anwender',
    en: 'User'
  },

  MENU_PUSH: {
    de: 'Push',
    en: 'Push'
  },

  MENU_INSTAGRAM_HANDLES: {
    de: 'Instagramhandles',
    en: 'Instagram Handles'
  },

  MENU_GWSREGISTRATIONS: {
    de: 'GWS Registrierungen',
    en: 'GWS Licenses'
  },

  MENU_STOREINSTALLATIONS: {
    de: 'Installationen',
    en: 'Installations'
  },

  MENU_INSTALLATIONS: {
    de: 'Downloads',
    en: 'Downloads'
  },

  MENU_VISITS: {
    de: 'Visitors',
    en: 'Visitors'
  },

  MENU_AGENCIES: {
    de: 'Agenturen',
    en: 'Agencies'
  },

  MENU_PLATFORMS: {
    de: 'Platforms',
    en: 'Platforms'
  },

  MENU_REGISTRATIONS: {
    de: 'Registrierungen',
    en: 'Registrations'
  },

  MENU_ERVVISITS: {
    de: 'Aktive Apps',
    en: 'Active apps'
  },

  MENU_USAGES: {
    de: 'Usages',
    en: 'Usages'
  },

  MENU_VOTINGS: {
    de: 'Votings',
    en: 'Votings'
  },

  MENU_VIDEOS: {
    de: 'Videos',
    en: 'Videos'
  },

  MENU_AUDIOS: {
    de: 'Podcasts',
    en: 'PodCasts'
  },

  MENU_ENGAGEMENT: {
    de: 'Beteiligung',
    en: 'Engagement'
  },

  MENU_SANKEY: {
    de: 'Nutzernavigation',
    en: 'User navigation'
  },

  MENU_SHIFT_WORK_PROGRESS: {
    de: 'Schichtarbeit Fortschritt',
    en: 'Shift work progress'
  },

  PAGE_LABEL_FILTER: {
    de: 'Filter',
    en: 'Filter'
  },

  PAGE_DELETE_FILTER: {
    de: 'Lösche Filter',
    en: 'delete filter'
  },

  ASSIGNMENT_LABEL_FILTER: {
    de: 'Filter',
    en: 'Filter'
  },

  ASSIGNMENT_DELETE_FILTER: {
    de: 'Lösche Filter',
    en: 'delete filter'
  },

  TITLE_OSBASED: {
    de: 'aktive Apps nach Betriebssystem',
    en: 'active apps operating system based'
  },

  TITLE_COUNTRYBASED: {
    de: 'aktive Apps nach Ländern',
    en: 'active apps country based'
  },

  TITLE_INSTALLATION: {
    de: 'Apps nach Status',
    en: 'apps state based'
  },

  LABEL_TIME_RESOLUTION: {
    de: 'Zeitraum',
    en: 'Time resolution'
  },

  OPTION_DAYLY: {
    de: 'pro Tag',
    en: 'per day'
  },

  OPTION_WEEKLY: {
    de: 'pro Woche',
    en: 'per week'
  },

  OPTION_MONTHLY: {
    de: 'pro Monat',
    en: 'per month'
  },

  OPTION_YEARLY: {
    de: 'pro Jahr',
    en: 'per year'
  },

  DATA_COLLECTION_DATE: {
    de: 'Datenstand',
    en: 'Data collection date'
  },

  DATE: {
    de: 'Datum',
    en: 'Date'
  },

  TRIALS: {
    de: 'trials',
    en: 'trials'
  },

  REGISTERED: {
    de: 'registered',
    en: 'registered'
  },

  TOTAL: {
    de: 'Gesamt',
    en: 'Total'
  },

  INSTALLATIONS_SINCE: {
    de: 'Gesamtinstallationen seit 2018-01',
    en: 'Total installations since 2018-01'
  },

  BOOKING_ADD_BUTTON: {
    de: 'Neue Buchung bestätigen',
    en: 'Add new booking'
  },

  BOOKING_ACCEPT_BUTTON: {
    de: 'Token erzeugen',
    en: 'Generate Token'
  },

  REGISTRATION_REQUEST_ZERO_FILTERED: {
    de: 'Keine Registrierungsanfragen für den eingestellten Filter vorhanden.',
    en: 'No registration requests for the set filter available.'
  },

  REGISTRATION_REQUEST_ZERO: {
    de: 'Noch keine Registrierungsanfragen erfasst.',
    en: 'No bookings stored.'
  },

  REGISTRATION_REQUEST_DELETE_TEXT: {
    de: 'Sind sie sich sicher die Registrierungsanfrage "<b>$0</b>" zu löschen?<br>Der Anfragende wird nicht automatisch informiert.<br>Der Anfragende kann aber jederzeit eine neue Registrierungsanfrage stellen.',
    en: 'Are you sure you want to delete the registration request: "<b>$0</b>"? <br>The inquirer is not automatically informed. <br> The inquirer can, however, submit a new registration request at any time.'
  },

  REGISTRATION_REQUEST_DELETE_HEADER: {
    de: 'Registrierungsanfrage löschen',
    en: 'Delete registration request'
  },

  REGISTRATION_REQUEST_ADD_BUTTON: {
    de: 'Email freigeben / sperren',
    en: 'Permit/Deny Email'
  },

  REGISTRATION_REQUEST_LABEL_EMAIL: {
    de: 'E-Mail',
    en: 'Email'
  },

  REGISTRATION_REQUEST_LABEL_COMPANY: {
    de: 'Unternehmen',
    en: 'Company'
  },

  REGISTRATION_REQUEST_LABEL_FIRSTNAME: {
    de: 'Vorname',
    en: 'Firstname'
  },

  REGISTRATION_REQUEST_LABEL_LASTNAME: {
    de: 'Nachname',
    en: 'Lastname'
  },

  REGISTRATION_REQUEST_LABEL_PHONE: {
    de: 'Telefon',
    en: 'Phone'
  },

  REGISTRATION_REQUEST_ADDITIONAL_DATA: {
    de: 'Zusatzdaten (JSON)',
    en: 'Additional Data (JSON)'
  },
  REGISTRATION_REQUEST_LABEL_ALLOW: {
    de: 'erlaube <b>$0</b>',
    en: 'allow <b>$0</b>'
  },

  REGISTRATION_REQUEST_LABEL_ALLOW_DOMAIN: {
    de: 'erlaube Domain <b>@$1</b>',
    en: 'allow Domain <b>@$1</b>'
  },

  REGISTRATION_REQUEST_LABEL_DENY: {
    de: 'verbiete <b>$0</b>',
    en: 'deny <b>$0</b>'
  },

  REGISTRATION_REQUEST_LABEL_DENY_DOMAIN: {
    de: 'verbiete Domain <b>@$1</b>',
    en: 'deny Domain <b>@$1</b>'
  },

  REGISTRATION_REQUEST_CREATE_BUTTON: {
    de: 'E-Mail Regel hinzufügen',
    en: 'Add email rule'
  },

  REGISTRATION_REQUEST_SAVE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  REGISTRATION_REQUEST_HEADLINE_CONTACT: {
    de: 'Kontakt',
    en: 'Contact person'
  },

  REGISTRATION_REQUEST_HEADLINE_STATE: {
    de: 'Zustand',
    en: 'State'
  },

  REGISTRATION_REQUEST_MAIL_ALLOWED: {
    de: 'E-Mail zugelassen',
    en: 'Mail allowed'
  },

  REGISTRATION_REQUEST_DOMAIN_ALLOWED: {
    de: 'Domain zugelassen',
    en: 'Domain allowed'
  },

  REGISTRATION_REQUEST_MAIL_DENIED: {
    de: 'E-Mail verboten',
    en: 'Mail denied'
  },

  REGISTRATION_REQUEST_DOMAIN_DENIED: {
    de: 'Domain verboten',
    en: 'Domain denied'
  },

  REGISTRATION_REQUEST_HEADLINE_DATE_CREATED: {
    de: 'Angefragt am',
    en: 'Requested at'
  },

  'Mail rule already exists': {
    de: 'Es existiert bereits eine Regel für diese E-Mail-Adresse.',
    en: 'Mail rule already exists.'
  },

  REGISTRATION_REQUEST_DOWNLOAD_REQUESTS: {
    de: 'Registrierungsanfragen',
    en: 'registration requests'
  },

  REGISTRATION_REQUEST_DOWNLOAD_USERS: {
    de: 'Registrierte User',
    en: 'registered users'
  },

  ERROR_REGISTRATION_REQUEST_INVALID_JSON: {
    de: 'JSON inkorrekt.',
    en: 'Invalid JSON'
  },

  NOTIFICATION_TEMPLATE_LABEL_NAME: {
    de: 'Name',
    en: 'Name'
  },

  NOTIFICATION_TEMPLATE_LABEL_SUBJECT: {
    de: 'Betreff',
    en: 'Subject'
  },

  NOTIFICATION_TEMPLATE_LABEL_CONTEXT: {
    de: 'Context',
    en: 'Context'
  },

  NOTIFICATION_TEMPLATE_LABEL_TEMPLATE: {
    de: 'Template',
    en: 'Template'
  },

  NOTIFICATION_TEMPLATE_LABEL_DEFAULT_FROM: {
    de: 'From',
    en: 'Default From'
  },

  NOTIFICATION_TEMPLATE_DEFAULT_REPLY_TO: {
    de: 'Reply To',
    en: 'ReplyTo'
  },

  NOTIFICATION_TEMPLATE_ZERO_FILTERED: {
    de: 'Keine E-Mail Templates für den eingestellten Filter vorhanden.',
    en: 'No email templates for the set filter available.'
  },

  NOTIFICATION_TEMPLATE_ZERO: {
    de: 'Noch keine E-Mail Templates erfasst.',
    en: 'No email templates stored.'
  },

  NOTIFICATION_TEMPLATE_DELETE_HEADER: {
    de: 'E-Mail Template löschen',
    en: 'Delete email template'
  },

  NOTIFICATION_TEMPLATE_DELETE_TEXT: {
    de: 'Sind sie sich sicher das E-Mail Template "<b>$0</b>" zu löschen?<br>Der Schritt kann nicht rückgängig gemacht werden.<br>Sie können aber jederzeit das E-Mail Template neu erfassen.',
    en: 'Are you sure you want to delete the email template: "<b>$0</b>"? <br>The step cannot be undone. <br> You can, however, re-enter the email template at any time.'
  },

  NOTIFICATION_TEMPLATE_ADD_BUTTON: {
    de: 'E-Mail Template hinzufügen',
    en: 'Add email template'
  },

  NOTIFICATION_TEMPLATE_EDIT_HEADER: {
    de: 'E-Mail Template bearbeiten',
    en: 'Edit email template'
  },

  NOTIFICATION_TEMPLATE_UPDATE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  NOTIFICATION_TEMPLATE_CREATE_BUTTON: {
    de: 'Speichern',
    en: 'Save'
  },

  ERROR_NOTIFICATION_TEMPLATE_EMAIL_WRONG: {
    de: 'Die Emailadresse scheint nicht korrekt zu sein.',
    en: 'The email address seems to be invalid.'
  },

  ERROR_NOTIFICATION_TEMPLATE_NAME_EXPECTED: {
    de: 'Der Name muss ausgefüllt werden.',
    en: "Name can't be empty."
  },

  ERROR_NOTIFICATION_TEMPLATE_INVALID_NAME: {
    de: 'Der Name sollte mindestens zwei Zeichen lang sein.',
    en: 'The name should have at least two characters.'
  },

  ERROR_NOTIFICATION_TEMPLATE_SUBJECT_EXPECTED: {
    de: 'Der Betreff muss ausgefüllt werden.',
    en: "Subject can't be empty."
  },

  ERROR_NOTIFICATION_TEMPLATE_INVALID_SUBJECT: {
    de: 'Der Betreff sollte mindestens zwei Zeichen lang sein.',
    en: 'The subject should have at least two characters.'
  },

  ERROR_NOTIFICATION_TEMPLATE_EXPECTED: {
    de: 'Das Template muss ausgefüllt werden.',
    en: "Template can't be empty."
  },

  ERROR_NOTIFICATION_TEMPLATE_SHORT: {
    de: 'das Template sollte mindestens zwei Zeichen lang sein.',
    en: 'The temlate should have at least two characters.'
  },

  JOB_HEADER_CAMPAIGN: {
    de: 'Kampagne',
    en: 'Campaign'
  },

  JOB_HEADER_TEMPLATE: {
    de: 'E-Mail Template',
    en: 'Mail template'
  },

  JOB_HEADER_DATE: {
    de: 'Versanddatum',
    en: 'Shipping date'
  },

  JOB_HEADER_STATUS: {
    de: 'Jobstatus',
    en: 'Job status'
  },

  JOB_ZERO_FILTERED: {
    de: 'Keine Jobs für den eingestellten Filter vorhanden.',
    en: 'No jobs for the set filter available.'
  },

  JOB_ZERO: {
    de: 'Noch keine Jobs erfasst.',
    en: 'No jobs stored.'
  },

  JOB_DELETE_HEADER: {
    de: 'Job löschen',
    en: 'Delete job'
  },

  JOB_DELETE_TEXT: {
    de: 'Sind sie sich sicher den Job "<b>$0</b>" zu löschen?<br>Der Schritt kann nicht rückgängig gemacht werden.<br>Sie können aber jederzeit einen Job neu erfassen.',
    en: 'Are you sure you want to delete the job: "<b>$0</b>"? <br>The step cannot be undone. <br> You can, however, re-enter a job at any time.'
  },

  JOB_ADD_BUTTON: {
    de: 'Job hinzufügen',
    en: 'Add job'
  },

  JOB_EDIT_HEADER: {
    de: 'Job bearbeiten',
    en: 'Edit job'
  },

  JOB_UPDATE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  JOB_CREATE_BUTTON: {
    de: 'Speichern',
    en: 'Save'
  },

  JOB_ERROR_ACTION_DATE_EXPECTED: {
    de: 'Versanddatum fehlt.',
    en: 'Shipping date expected.'
  },

  JOB_ERROR_INVALID_DATE: {
    de: 'Versanddatum ungültig.',
    en: 'Shipping date invalid.'
  },

  JOB_ERROR_NOTIFICATION_EXPECTED: {
    de: 'E-Mail Template fehlt.',
    en: 'Mail template expected.'
  },

  JOB_ERROR_CAMPAIGN_EXPECTED: {
    de: 'Kampagne fehlt.',
    en: 'Campaign expected.'
  },

  JOB_SUGGEST_HELPER: {
    de: 'Tippen sie ein paar Buchstaben für Vorschläge ...',
    en: 'Type a few letters to get suggestions ...'
  },

  CAMPAIGN_HEADER_ID: {
    de: 'Kampagnenid',
    en: 'Campaign Id'
  },

  CAMPAIGN_HEADER_TITLE: {
    de: 'Kampagnentitle',
    en: 'Campaign Title'
  },

  CAMPAIGN_HEADER_SUBSCRIBERS: {
    de: 'Abonnenten',
    en: 'Subscribers'
  },

  CAMPAIGN_SUBSCRIPTION_DOWNLOAD_USERS: {
    de: 'Download Abonnenten',
    en: 'Download Subscritions'
  },

  CAMPAIGN_ZERO_FILTERED: {
    de: 'Keine Kampagnen für den eingestellten Filter vorhanden.',
    en: 'No campaigns for the set filter available.'
  },

  CAMPAIGN_ZERO: {
    de: 'Noch keine Kampagnen erfasst.',
    en: 'No campaigns stored.'
  },

  CAMPAIGN_DELETE_HEADER: {
    de: 'Kampagne löschen',
    en: 'Delete campaign'
  },

  CAMPAIGN_DELETE_TEXT: {
    de: 'Sind sie sich sicher die Kampagne "<b>$0</b>" zu löschen?<br>Es werden noch nicht ausgeführte Jobs dieser Kampagne ebenfalls gelöscht.<br>Der Schritt kann nicht rückgängig gemacht werden.<br>Sie können aber jederzeit Kampagne und Jobs neu erfassen.',
    en: 'Are you sure you want to delete the campaign: "<b>$0</b>"? <br>Jobs that have not yet been executed in this campaign are also deleted.<br>The step cannot be undone. <br> You can, however, re-enter a campaign with jobs at any time.'
  },

  CAMPAIGN_ADD_BUTTON: {
    de: 'Kampagne hinzufügen',
    en: 'Add  Campaign'
  },

  CAMPAIGN_EDIT_HEADER: {
    de: 'Kampagne bearbeiten',
    en: 'Edit campaign'
  },

  CAMPAIGN_UPDATE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  CAMPAIGN_CREATE_BUTTON: {
    de: 'Speichern',
    en: 'Save'
  },

  ERROR_CAMPAIGN_ID_EXPECTED: {
    de: 'Kampagnenid fehlt.',
    en: 'Campaign id expected.'
  },

  ERROR_CAMPAIGN_ID_INVALID: {
    de: 'Die Kampagnenid sollte mindestens zwei Zeichen lang sein.',
    en: 'The campaign id should have at least two characters.'
  },

  ERROR_CAMPAIGN_TITLE_EXPECTED: {
    de: 'Kampagnentitel fehlt.',
    en: 'Campaign title expected.'
  },

  ERROR_CAMPAIGN_TITLE_INVALID: {
    de: 'Der Kampagnentitel sollte mindestens zwei Zeichen lang sein.',
    en: 'The campaign title should have at least two characters.'
  },

  ACTIVATION_CODE_HEADER_CODE: {
    de: 'Activationcode',
    en: 'Activation Code'
  },

  ACTIVATION_CODE_HEADER_FEATURES: {
    de: 'Features',
    en: 'Features'
  },

  ACTIVATION_CODE_HEADER_VALID: {
    de: 'gültig',
    en: 'valid'
  },

  ACTIVATION_CODE_HEADER_MAX_CLIENTS: {
    de: 'max. Clients',
    en: 'max. Clients'
  },

  ACTIVATION_CODE_HEADER_MAX_USERS: {
    de: 'max. Users',
    en: 'max. Users'
  },

  ACTIVATION_CODE_HEADER_USAGES: {
    de: 'Verwendungen',
    en: 'Usages'
  },

  ACTIVATION_CODE_ZERO_FILTERED: {
    de: 'Keine Activierungscodes für den eingestellten Filter vorhanden.',
    en: 'No activation codes for the set filter available.'
  },

  ACTIVATION_CODE_ZERO: {
    de: 'Noch keine Activierungscodes erfasst.',
    en: 'No activation codes stored.'
  },

  ACTIVATION_CODE_DELETE_HEADER: {
    de: 'Activationcode löschen',
    en: 'Delete activation code'
  },

  ACTIVATION_CODE_DELETE_TEXT: {
    de: 'Sind sie sich sicher den Activationcode "<b>$0</b>" zu löschen?<br>Es wird auch die zugehörige Statistik gelöscht, und die Nutzer können die Apps nicht mehr benutzen.<br>Der Schritt kann nicht rückgängig gemacht werden.',
    en: 'Are you sure you want to delete the activation code: "<b>$0</b>"? <br>The associated statistics are also deleted and users can no longer use the apps.<br>The step cannot be undone.<br>'
  },

  ACTIVATION_CODE_ADD_BUTTON: {
    de: 'Activationcode anlegen',
    en: 'Add activation code'
  },

  ACTIVATION_CODE_EDIT_HEADER: {
    de: 'Activationcode bearbeiten',
    en: 'Edit activation code'
  },

  ACTIVATION_CODE_UPDATE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  ACTIVATION_CODE_CREATE_BUTTON: {
    de: 'Speichern',
    en: 'Save'
  },

  DNS_HEADLINE_NAME: {
    de: 'Subdomain',
    en: 'sub domain'
  },

  DNS_HEADLINE_START_TIME: {
    de: 'Startdatum',
    en: 'Start date'
  },

  DNS_ZERO_FILTERED: {
    de: 'Keine Dns Einträge für den eingestellten Filter vorhanden.',
    en: 'No dns entries for the set filter available.'
  },

  DNS_ZERO: {
    de: 'Noch keine Dns Einträge hinterlegt.',
    en: 'No dns entries stored.'
  },

  DNS_DELETE_HEADER: {
    de: 'Dns Eintrag löschen',
    en: 'Delete dns entry'
  },

  DNS_DELETE_TEXT: {
    de: 'Sind sie sich sicher den DNS Eintrag "<b>$0</b>" zu löschen?<br> Bedenken Sie das Änderungen im DNS ggfs. erst zwei Stunden später wirksam werden.',
    en: 'Are you sure you want to delete the dns entry "<b>$0</b>"? <br> Remind that changes can be need up to 2 hours, until clients detect them.'
  },

  DNS_ADD_BUTTON: {
    de: 'Dns Eintrag hinzufügen',
    en: 'Add dns entry'
  },

  DNS_SAVE_BUTTON: {
    de: 'Speichern',
    en: 'Save'
  },

  DNS_EDIT_HEADER: {
    de: 'Dns Eintrag bearbeiten',
    en: 'Edit dns entry'
  },

  DNS_UPDATE_BUTTON: {
    de: 'Aktualisieren',
    en: 'Update'
  },

  DNS_LABEL_SUBDOMAIN_NAME: {
    de: 'Subdomain',
    en: 'sub domain'
  },

  DNS_LABEL_STARTTIME: {
    de: 'startet ab',
    en: 'starts from'
  },

  PUSH_SEND_BUTTON: {
    de: 'Pushnachricht versenden',
    en: 'send push message'
  },

  PUSH_HEADLINE_TITLE: {
    de: 'Titel',
    en: 'Title'
  },

  PUSH_HEADLINE_MESSAGE: {
    de: 'Nachricht',
    en: 'Message'
  },

  PUSH_HEADLINE_SENT: {
    de: 'Versanddatum',
    en: 'Sent Date'
  },

  PUSH_HEADLINE_RECEIVERS: {
    de: 'Apps',
    en: 'Apps'
  },

  PUSH_HEADLINE_ERRORS: {
    de: 'Sendefehler',
    en: 'Errors'
  },

  PUSH_ZERO_FILTERED: {
    de: 'Keine Nachrichten für den eingestellten Filter vorhanden.',
    en: 'No messages for the set filter available.'
  },

  PUSH_ZERO: {
    de: 'Noch keine Nachrichten versendet.',
    en: 'No Messages sent.'
  },

  PUSH_VIEW_HEADER: {
    de: 'Versendete Push Message',
    en: 'Push Message'
  },

  BACK_BUTTON: {
    de: 'Zurück',
    en: 'Return'
  },

  PUSH_SAVE_BUTTON: {
    de: 'Versenden',
    en: 'Send'
  },

  PUSH_LABEL_NAVIGATETO: {
    de: 'Gehe zu Kachel',
    en: 'Navigate to tile'
  },

  PUSH_HELPER_NAVIGATETO: {
    de: 'Suche die Kachel (tippe einige bekannte Zeichen vom Titel oder der Id)',
    en: 'Search tile (tye some letters of the title or the lecture id)'
  },

  PUSH_TAB_DEVICES: {
    de: 'Geräte',
    en: 'Devices'
  },

  PUSH_TAB_PERSONAL_NUMBERS: {
    de: 'Personen',
    en: 'Persons'
  },

  PUSH_LABEL_RECEIVER: {
    de: 'Empfängerliste',
    en: 'Receiver list'
  },

  PUSH_HELPER_RECEIVER: {
    de: 'Eine Liste von Servicekennungen von Apps, welche ausschließlich diese PushMessage erhalten.',
    en: 'A list of service IDs for apps that only receive this Push Notification.'
  },

  PUSH_LABEL_PERSONS: {
    de: 'Empfängerliste',
    en: 'Receiver list'
  },

  PUSH_HELPER_PERSONS: {
    de: 'Eine Liste von Personalnummern, welche ausschließlich diese PushMessage erhalten.',
    en: 'A list of personnel numbers that only receive this push message.'
  },

  ERROR_PUSH_MESSAGE_EMPTY: {
    de: 'Nachricht muss ausgefüllt werden',
    en: 'Message expected'
  },

  ERROR_PUSH_MESSAGE_TOOLONG: {
    de: 'Nachricht hat mehr als 200 Zeichen',
    en: 'Message has more than 200 characters'
  },

  ERROR_PUSH_TITLE_TOOLONG: {
    de: 'Titel hat mehr als 100 Zeichen',
    en: 'Title has more than 100 characters'
  },

  ERROR_PUSH_TILENOTNULL: {
    de: 'Bitte existierende Kachel auswählen',
    en: 'Choose a Tile for navigate to'
  },

  ERROR_PUSH_APPFILTERWRONG: {
    de: 'Servicekennungen müssen komplett mit 12 hexadezimalen Ziffern angegeben werden, und sind durch Kommas getrennt',
    en: 'Service IDs must be specified in full with 12 hexadecimal digits and are separated by commas'
  },

  ERROR_PUSH_REQUESTCODESWRONG: {
    de: 'RequestCodes müssen Personalnummern sein, üblicherweise 6stellig ohne führende Buchstaben, und sind durch Kommas getrennt',
    en: 'Request codes must be personnel numbers, usually 6 digits without leading letters, and are separated by commas'
  },

  BOOKINGS_ZERO_FILTERED: {
    de: 'Keine Buchungen für den eingestellten Filter vorhanden.',
    en: 'No bookings for the set filter available.'
  },

  BOOKINGS_ZERO: {
    de: 'Noch keine Buchungen bestätigt.',
    en: 'No bookings accepted.'
  },

  BOOKING_HEADLINE_USER: {
    de: 'Nutzer',
    en: 'User'
  },

  BOOKING_HEADLINE_COURSE: {
    de: 'Prüfung',
    en: 'Examination'
  },

  BOOKING_HEADLINE_ACCEPTED: {
    de: 'freigeschaltet am',
    en: 'accepted at'
  },

  BOOKING_LABEL_EMAIL: {
    de: 'E-Mail',
    en: 'Email'
  },

  BOOKING_LABEL_FIRSTNAME: {
    de: 'Vorname',
    en: 'Firstname'
  },

  BOOKING_LABEL_LASTNAME: {
    de: 'Nachname',
    en: 'Lastname'
  },

  BOOKING_LABEL_COURSE: {
    de: 'Prüfungstermin',
    en: 'Examination date'
  },

  BOOKING_LABEL_COMMENT: {
    de: 'Bemerkungen',
    en: 'Comments'
  },

  BOOKING_LABEL_COMPANY: {
    de: 'Mitgliedsunternehmen',
    en: 'company'
  },

  BOOKING_LABEL_COMPANYADDRESS: {
    de: 'Adresse des Unternehmens',
    en: 'company address'
  },

  BOOKING_LABEL_RESERVATIONS: {
    de: 'gewünschte Zimmerreservierungen',
    en: 'whished Hotel bookings'
  },

  BOOKING_LABEL_CONTACTPERSON: {
    de: 'Ansprechpartner',
    en: 'contact person'
  },

  BOOKING_LABEL_ONETIMEOKEN: {
    de: 'Einmaltoken',
    en: 'one time token'
  },

  BOOKING_LABEL_REGISTERED: {
    de: 'Nutzerstatus',
    en: 'User state'
  },

  BOOKING_LABEL_ACCEPTEDDATE: {
    de: 'bestätigt am',
    en: 'accepted at'
  },

  BOOKING_LABEL_DATECREATED: {
    de: 'erfasst',
    en: 'Date created'
  },

  BOOKING_LABEL_LASTUDATED: {
    de: 'aktualisiert',
    en: 'last updated'
  },

  ERROR_BOOKING_EMAILEMPTY: {
    de: 'E-Mail erwartet',
    en: 'Email empty'
  },

  ERROR_BOOKING_EMAILWRONG: {
    de: 'E-Mail nicht korrekt',
    en: 'Email wrong'
  },

  ERROR_BOOKING_INVALIDFIRSTNAME: {
    de: 'Der Vorname sollte mindestens zwei Zeichen lang sein.',
    en: 'The firstname should have at least two characters.'
  },

  ERROR_BOOKING_INVALIDLASTNAME: {
    de: 'Der Nachname sollte mindestens zwei Zeichen lang sein.',
    en: 'The lastname should have at least two characters.'
  },

  ERROR_BOOKING_INVALIDEXAMINATION: {
    de: 'Ungültiger Prüfungstermin gewählt.',
    en: 'Invalid examination date choosen.'
  },

  ERROR_BOOKING_EXAMINATIONEMPTY: {
    de: 'Bitte wählen Sie einen Prüfungstermin.',
    en: 'No examination date choosen.'
  },

  ERROR_REGISTRATION_REQUEST_COMPANY_EMPTY: {
    de: 'Firmenname erwartet.',
    en: 'Company empty.'
  },

  NOTIFICATION_TEMPLATE_SEND_TITLE: {
    de: 'Sende Testmail',
    en: 'Send test mail'
  },

  NOTIFICATION_TEMPLATE_SEND_COPY: {
    de: 'Geben Sie Empfänger für das Template <b>$0</b> an.<br>Beachten Sie das die Empfänger registrierte Nutzer sein müssen.<br>Trennen Sie mehrere Empfänger mit einem Zeilenumbruch oder Komma.',
    en: 'Enter receivers for the template <b>$0</b>.<br>Note that the recipients must be registered users.<br>Separate multiple recipients with a line break or comma.'
  },

  NOTIFICATION_TEMPLATE_SEND_LABEL_RECEIVERS: {
    de: 'Empfänger',
    en: 'Receivers'
  },

  NOTIFICATION_TEMPLATE_SEND_SUBMIT: {
    de: 'Versende E-Mails',
    en: 'Send emails'
  },

  ERROR_MAIL_SEND_RECEIVERS_EXPECTED: {
    de: 'Sie müssen mindestens einen Empfänger angeben.',
    en: 'You must specify at least one receiver.'
  },

  ERROR_MAIL_SEND_RECEIVERS_WRONG: {
    de: 'Die Empfängerliste enthält fehlerhafte E-Mail Adressen.',
    en: 'The receiver list contains incorrect email addresses.'
  },

  BOOKINGS_DELETE_TEXT: {
    de: 'Sind sie sich sicher die Bestätigung "<b>$0</b>" zu löschen?<br>Der Nutzer kann den Kurs dann nicht mehr sehen.<br>Sie können Ihn aber jederzeit wieder anmelden.',
    en: 'Are you sure you want to delete the Examination booing: "<b>$0</b>"? <br>The user can\'t view course content aafter that.<br>You can always reassign the user to the course.'
  },

  BOOKINGS_DELETE_HEADER: {
    de: 'Prüfungsanmeldung löschen',
    en: 'Delete booking'
  },

  BOOKING_TAB_USER: {
    de: 'Nutzeranmeldung',
    en: 'User registration'
  },

  BOOKING_TAB_TOKEN: {
    de: 'Einmaltoken',
    en: 'One time token'
  },

  BOOKING_TAB_DETAILS: {
    de: 'Details',
    en: 'Details'
  },

  BOOKING_SHOW_HEADLINE: {
    de: 'Buchung und Token ansehen',
    en: 'View Booking and details'
  },

  EPRUSER_HEADLINE_FIRSTNAME: {
    de: 'Vorname',
    en: 'Firstname'
  },

  EPRUSER_HEADLINE_LASTNAME: {
    de: 'Nachname',
    en: 'Lastname'
  },

  EPRUSER_HEADLINE_EMAIL: {
    de: 'E-Mail',
    en: 'Email'
  },

  EPRUSER_HEADLINE_DATE_CREATED: {
    de: 'Erfasst',
    en: 'Date created'
  },

  EPRUSER_HEADLINE_LAST_ACCESS: {
    de: 'Letzter Zugriff',
    en: 'Last access'
  },

  EPRUSER_ZERO_FILTERED: {
    de: 'Keine Anwender für den eingestellten Filter vorhanden.',
    en: 'No users for the set filter available.'
  },

  EPRUSER_ZERO: {
    de: 'Noch keine Anwender angemeldet.',
    en: 'No user entries created.'
  },

  EPRUSER_DELETE_HEADER: {
    de: 'Anwender löschen',
    en: 'Delete User'
  },

  EPRUSER_DELETE_TEXT: {
    de: 'Sind sie sich sicher den Anwender "<b>$0</b>" zu löschen?<br> Alle zu ihm gespeicherten Daten werden gelöscht. Falls dieser die Plattform wieder nutzen will, muss er sich erneut anmelden.',
    en: 'Are you sure you want to delete the user "<b>$0</b>"? <br> All data stored about him will be deleted. If they want to use the platform again, they have to log in again.'
  },

  SELECTOR_LABEL_SUBDOMAIN: {
    de: 'Herkunft',
    en: 'Origin'
  },

  SELECTOR_LABEL_SALESREGION: {
    de: 'Vertriebsregion',
    en: 'Sales region'
  },

  SELECTOR_LABEL_COUNTRY: {
    de: 'Land',
    en: 'Country'
  },

  SELECTOR_LABEL_ORIGIN: {
    de: 'Herkunft',
    en: 'Origin'
  },

  SELECTOR_LABEL_AGENCY: {
    de: 'Agentur',
    en: 'Agency'
  },

  SANKEY_DIALOG_TITLE: {
    de: 'Diagrammeinstellungen',
    en: 'Diagram options'
  },

  SANKEY_OPTIONS_CLOSE: {
    de: 'Schliessen',
    en: 'Close'
  },

  SANKEY_DIALOG_LABEL_RANGE: {
    de: 'Anzahl Navigationen',
    en: 'Navigation count'
  },

  SANKEY_DIALOG_LABEL_SCALE_LOGARITHMIC: {
    de: 'logarithmische Skalierung',
    en: 'scale logarithmic'
  },

  SANKEY_DIALOG_LABEL_SHOW_VERTICAL: {
    de: 'Zeige Senkrecht an',
    en: 'show vertical'
  },

  SANKEY_DIALOG_LABEL_DIAGRAM_HEIGHT: {
    de: 'Höhe des Diagramms',
    en: 'Diagram height'
  },

  SANKEY_DIAGRAM_LOGARITHMIC_TITLE: {
    de: 'Anzahl Navigationen (logarithmisch)',
    en: 'Logarithmic navigation count'
  },

  SANKEY_DIAGRAM_ABSOLUTE_TITLE: {
    de: 'Anzahl Navigationen (absolut)',
    en: 'Absolute navigation count'
  },

  SANKEY_DIAGRAM_HEIGHT_ERROR_UNDEFINED: {
    de: 'Diagrammhöhe muss definiert sein.',
    en: 'Diagram height expected.'
  },

  SANKEY_DIAGRAM_HEIGHT_ERROR_INVALID: {
    de: 'Diagrammhöhe ist keine natürliche Zahl  zwischen 100 bis 5000',
    en: 'Diagram height is not a natural number between 100 and 5000'
  },

  NAVIGATION_DETAILS_TITLE: {
    de: 'Navigationen von $0',
    en: 'Navigations from $0'
  },

  ACTIVATION_CODE_LABEL_CODE: {
    de: 'Code',
    en: 'Code'
  },

  ACTIVATION_CODE_LABEL_FEATURE: {
    de: 'Feature',
    en: 'Feature'
  },

  ACTIVATION_CODE_LABEL_VALID_FROM: {
    de: 'Gültig ab',
    en: 'Valid from'
  },

  ACTIVATION_CODE_LABEL_VALID_UNTIL: {
    de: 'Gültig bis',
    en: 'Valid until'
  },

  ACTIVATION_CODE_LABEL_VALID_DAYS: {
    de: 'Tage ab Aktivierung',
    en: 'Days after activation'
  },

  ACTIVATION_CODE_LABEL_REVOKED: {
    de: 'Zurückgezogen',
    en: 'Revoked'
  },

  ACTIVATION_CODE_LABEL_ACTIVATE_UNTIL: {
    de: 'Aktivierbar bis',
    en: 'Activate until'
  },

  ACTIVATION_CODE_LABEL_FIRST_ACTIVATE_UNTIL: {
    de: 'Erstmalig aktivierbar bis',
    en: 'first activation until'
  },

  ACTIVATION_CODE_LABEL_MAX_CLIENTS: {
    de: 'Max. Clients',
    en: 'Max. clients'
  },

  ACTIVATION_CODE_LABEL_ONLY_ONE_DEVICE: {
    de: 'Nur ein Client pro User',
    en: 'Only one device'
  },

  ACTIVATION_CODE_LABEL_MUST_PERSONALIZED: {
    de: 'Benötigt User',
    en: 'Must personalized'
  },

  ACTIVATION_CODE_LABEL_ONLY_ONE_USER: {
    de: 'Max. ein User',
    en: 'Only one User'
  },

  ACTIVATION_CODE_LABEL_REGISTRATION_TOKEN_LENGTH: {
    de: 'Registrierungstokenlänge',
    en: 'Registration token length'
  },

  ACTIVATION_CODE_LABEL_ALLOW_PASSWORD_RESET: {
    de: 'Passwordreset erlauben',
    en: 'Allow password reset'
  },

  ACTIVATION_CODE_LABEL_PASSWORD_RESET_TOKEN_LENGTH: {
    de: 'Passwortresettokenlänge',
    en: 'Password reset token length'
  },

  ACTIVATION_CODE_LABEL_REQUIRES_EMAIL: {
    de: 'Benötigt E-Mail',
    en: 'Requires email'
  },

  ACTIVATION_CODE_LABEL_EXPOSE_MAIL_DOMAINS: {
    de: 'Übermittle Maildomains',
    en: 'Expose mail domains'
  },

  ACTIVATION_CODE_LABEL_ALLOWED_MAIL_DOMAINS: {
    de: 'Erlaubte Maildomains (JSON Array)',
    en: 'Allowed mail domains (JSON Array)'
  },

  ACTIVATION_CODE_LABEL_PUBLIC_PATHS: {
    de: 'Öffentlich Pfade (JSON Array)',
    en: 'Public paths (JSON Array)'
  },

  ACTIVATION_CODE_LABEL_ADDITIONAL_DATA: {
    de: 'Zusatzdaten (JSON)',
    en: 'Additional Data (JSON)'
  },

  ACTIVATION_CODE_SUBTITLE_VALIDITY: {
    de: 'Gültigkeit',
    en: 'Validity'
  },

  ACTIVATION_CODE_SUBTITLE_PERSONALIZATION: {
    de: 'Personalisierung',
    en: 'Personalization'
  },

  ACTIVATION_CODE_SUBTITLE_MAIL_HANDLING: {
    de: 'E-Mail-Behandlung',
    en: 'Mail handling'
  },

  ACTIVATION_CODE_SUBTITLE_ADDITIONAL_DATA: {
    de: 'Zusatzdaten',
    en: 'Additional data'
  },

  ACTIVATION_CODE_SUBTITLE_ACTION_PARAMS: {
    de: 'Config Message Parameter',
    en: 'Config message params'
  },

  ACTIVATION_CODE_LABEL_ACTION_PARAMS: {
    de: 'Parameter für checkActivationCode SysMessage (JSON)',
    en: 'Additional params for checkActivationCode sys message (JSON)'
  },

  ERROR_CODE_EXPECTED: {
    de: 'Activationcode erwartet.',
    en: 'Activation code expected.'
  },

  ERROR_CODE_INVALID: {
    de: 'Activationcode ungültig.',
    en: 'Activation code invalid.'
  },

  ERROR_FEATURE_INVALID: {
    de: 'Featurenamen ungültig.',
    en: 'Feature names invalid.'
  },

  ERROR_MAX_CLIENTS_INVALID: {
    de: 'Max. Clients ungültig.',
    en: 'Max. Clients invalid.'
  },

  ERROR_ACTIVATION_CODE_FROM_AFTER_UNTIL: {
    de: 'Gültig ab ist nach gültig bis.',
    en: 'Valid from is after valid from.'
  },

  ERROR_ACTIVATION_CODE_NO_VALID_DATE: {
    de: 'Kein gültiges Datum.',
    en: 'No valid date.'
  },

  ERROR_ACTIVATION_CODE_UNTIL_BEFORE_FROM: {
    de: 'Gültig bis ist vor gültig von.',
    en: 'Valid until sis before valid from.'
  },

  ERROR_VALID_DAYS_INVALID: {
    de: 'Tage müssen größer als 0 sein.',
    en: 'Days must be greater than zero.'
  },

  ERROR_ACTIVATION_CODE_ACTIVATE_BEFORE_FROM: {
    de: 'Muss größer als gültig von sein.',
    en: 'Should be greater than valid from.'
  },

  QRCODE_OPTION_SCHICHTBOX: {
    de: 'equeo Schichtbox',
    en: 'equeo Schichtbox'
  },

  ACTIVATION_CODE_QR_APP_HEADER: {
    de: 'QR Code für Link zum Appstore und Init',
    en: 'QR code for link to app store and init'
  },

  QRCODE_LABEL_APPNAME: {
    de: 'App Name',
    en: 'Appname'
  },

  SHOW_APP_LOGO: {
    de: 'zeige Logo',
    en: 'apply Logo'
  },

  ACTIVATION_CODE_DEACTIVATE_HEADER: {
    de: 'Activationcode deaktivieren',
    en: 'Deactivate activation code'
  },

  ACTIVATION_CODE_DEACTIVATE_BUTTON: {
    de: 'Durchführen',
    en: 'Execute'
  },

  DEACTIVATE_TYPE_LABEL: {
    de: 'Art der Deaktivierung',
    en: 'Deactivation type'
  },

  ACTIVATION_CODE_DEACTIVATE: {
    de: 'Verhindern weiterer Aktivierungen mit dem Activationcode"<b>$0</b>".<br>Bereits installierte und aktivierte Apps können weiter benutzt werden.',
    en: 'Prevent further activations with the activation code "<b>$0</b>".<br>Apps that have already been installed and activated can continue to be used.'
  },

  ACTIVATION_CODE_DISABLE: {
    de: 'Verhindern weiterer Aktivierungen und Benutzung mit dem Activationcode"<b>$0</b>".',
    en: 'Prevent further activations and usae with the activation code "<b>$0</b>".'
  },

  ACTIVATION_CODE_DELETE: {
    de: 'Löschen des Activationcodes "<b>$0</b>".<br>Es wird auch die zugehörige Statistik gelöscht, und die Nutzer können die Apps nicht mehr benutzen.<br>Der Schritt kann nicht rückgängig gemacht werden.',
    en: 'Delete the activation code "<b>$0</b>".<br>The associated statistics are also deleted and users can no longer use the apps.<br>The step cannot be undone.<br>'
  },

  INSTAGRAM_HEADLINE_PERSONAL_NUMBER: {
    de: 'Personalnummer',
    en: 'Personal number'
  },

  INSTAGRAM_HEADLINE_HEAD_FIRSTNAME: {
    de: 'Vorname',
    en: 'Firstname'
  },

  INSTAGRAM_HEADLINE_LASTNAME: {
    de: 'Nachname',
    en: 'Lastname'
  },

  HEAD_LINE_POLICIES: {
    de: 'Policen',
    en: 'Policies'
  },

  HEAD_LINE_CLIENTS: {
    de: 'Apps',
    en: 'Apps'
  },

  HEAD_NEW_POLICIES: {
    de: 'Neue Policies',
    en: 'New policies'
  },

  HEAD_AVAILABLE_POLICIES: {
    de: 'Gültige Policies',
    en: 'Availablle Policies'
  },

  HEAD_NEW_CLIENTS: {
    de: 'Neue App-Registrierungen',
    en: 'New app registrations'
  },

  HEAD_AVAILABLE_CLIENTS: {
    de: 'Active Apps',
    en: 'Available apps'
  },

  HEAD_LINE_CLIENT: {
    de: 'App',
    en: 'App instance'
  },

  HEAD_LINE_START_DATE: {
    de: 'Erste Nutzung',
    en: 'First usage'
  },

  HEAD_LINE_SLEEP_BEGIN_ANSWERS: {
    de: 'Schlaf vorher',
    en: 'Sleep start survey'
  },

  HEAD_LINE_SLEEP_BEGIN_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_SLEEP_DAYS: {
    de: 'Schlaf Tage',
    en: 'Day Sleep'
  },

  HEAD_LINE_SLEEP_DAY_DATE: {
    de: 'erreicht am',
    en: 'reached at'
  },

  HEAD_LINE_SLEEP_END_ANSWERS: {
    de: 'Schlaf nachher',
    en: 'Sleep end survey'
  },

  HEAD_LINE_SLEEP_END_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_STRESS_BEGIN_ANSWERS: {
    de: 'Stress vorher',
    en: 'Stress start survey'
  },

  HEAD_LINE_STRESS_BEGIN_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_STRESS_DAYS: {
    de: 'Stress Tage',
    en: 'Day stress'
  },

  HEAD_LINE_STRESS_DAY_DATE: {
    de: 'erreicht am',
    en: 'reached at'
  },

  HEAD_LINE_STRESS_END_ANSWERS: {
    de: 'Stress nachher',
    en: 'Stress end survey'
  },

  HEAD_LINE_STRESS_END_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_NUTRITION_BEGIN_ANSWERS: {
    de: 'Ernährung vorher',
    en: 'Nutrition start survey'
  },

  HEAD_LINE_NUTRITION_BEGIN_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_NUTRITION_DAYS: {
    de: 'Ernährung Tage',
    en: 'Day nutrition'
  },

  HEAD_LINE_NUTRITION_DAY_DATE: {
    de: 'erreicht am',
    en: 'reached at'
  },

  HEAD_LINE_NUTRITION_END_ANSWERS: {
    de: 'Ernährung nachher',
    en: 'Nutrition end survey'
  },

  HEAD_LINE_NUTRITION_END_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_CONTACTS_BEGIN_ANSWERS: {
    de: 'Soziale Kontakte vorher',
    en: 'Social Contacts start survey'
  },

  HEAD_LINE_CONTACTS_BEGIN_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_CONTACTS_DAYS: {
    de: 'Soziale Kontakte Tage',
    en: 'Day social contacts'
  },

  HEAD_LINE_CONTACTS_DAY_DATE: {
    de: 'erreicht am',
    en: 'reached at'
  },

  HEAD_LINE_CONTACTS_END_ANSWERS: {
    de: 'Soziale Kontakte nachher',
    en: 'Social Contacts end survey'
  },

  HEAD_LINE_CONTACTS_END_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_MOTION_BEGIN_ANSWERS: {
    de: 'Bewegung vorher',
    en: 'Motion start survey'
  },

  HEAD_LINE_MOTION_BEGIN_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  },

  HEAD_LINE_MOTION_DAYS: {
    de: 'Bewegung Tage',
    en: 'Day motion'
  },

  HEAD_LINE_MOTION_DAY_DATE: {
    de: 'erreicht am',
    en: 'reached at'
  },

  HEAD_LINE_MOTION_END_ANSWERS: {
    de: 'Bewegung nachher',
    en: 'Motion end survey'
  },

  HEAD_LINE_MOTION_END_DATE: {
    de: 'ausgefüllt am',
    en: 'filled at'
  }
}

const applyArgsToString = (string, args) => {
  let stringWithAppliedArgs = string
  let i = 0
  args.forEach((arg) => {
    stringWithAppliedArgs = stringWithAppliedArgs.replace(`$${i}`, arg)
    i += 1
  })
  return stringWithAppliedArgs
}

export let target = {
  supportedLanguages: ['de', 'en'],
  choosenLanguage: 'de'
}

export const dateLocale = () => {
  return target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
}

/**
 * Returns the string for the requested key in the prefererred language.
 * If it is not found all languages of higher priority are checked (as specified in config or target config).
 * If not present there either the key itself will be returned
 *
 * If no preferred language is given, the first language as specified in config or target config is used.
 */
const getString = (key, args = [], preferredLanguage = target.choosenLanguage) => {
  // console.log('INFO','strings.getString', key, preferredLanguage)
  // return 'key' if key does not exist
  if (!STRINGS[key]) {
    if (!args || args.length > 0) {
      return `${key} (${JSON.stringify(args)}`
    }
    return key
  }

  const strings = STRINGS[key] ? STRINGS[key] : {}

  // check if string exists in preferred language
  if (strings[preferredLanguage]) {
    return applyArgsToString(strings[preferredLanguage], args)
  }

  // find best language
  const bestAvailLanguage = target.supportedLanguages.filter((language) => Object.keys(strings).indexOf(language) >= 0)
  if (bestAvailLanguage.length) {
    return applyArgsToString(strings[bestAvailLanguage[0]], args)
  } else {
    if (!args || args.length > 0) {
      return `${key} (${JSON.stringify(args)}`
    }
    return key
  }
}

export default getString
