import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import AudioView from '../AudioView'
import ApiService from '../../services/APIService'
import { LinkSelector } from '../LinkSelector'
import FilterField from '../library/pageComponents/FilterField'
import { refreshAuth } from './helpers/pageHelper'
import {
  contentTagsFromAuth,
  validateContentTagFromProps,
  normalizeTitle,
  sourceSelectorFromAuth,
  validateSubDomainFromProps,
  clientsToHostNames
} from '../utils/helperFunctions'

const styles = (theme) => ({
  middleBox: {
    textAlign: 'right'
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

class AudiosPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      filter: '',
      fetchDate: '',
      audios: [],
      contentTag: validateContentTagFromProps(props),
      subDomain: validateSubDomainFromProps(props),
      hostNames: Object.assign({}, props.hostNames)
    }
  }

  componentDidMount() {
    this.updateStats()
  }

  static getDerivedStateFromProps(props, state) {
    const contentTag = validateContentTagFromProps(props)
    const subDomain = validateSubDomainFromProps(props)

    if (contentTag !== state.contentTag || subDomain !== state.subDomain) {
      return { contentTag: contentTag, subDomain: subDomain, isLoading: true }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevState.contentTag !== this.state.contentTag || prevState.subDomain !== this.state.subDomain) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {
        tabActions.updateIndicator()
      }, 0)
    }
  }

  async updateStats() {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const contentTag = this.state.contentTag

      const audios = await ApiService.getAudios(newAuth, contentTag)
      const clients = await ApiService.getClients(newAuth, [contentTag])
      const client2HostName = clientsToHostNames(clients, newAuth, contentTag)
      const filterSubDomains = sourceSelectorFromAuth(newAuth)
      const isLernminiaturen = contentTag === 'lernminiaturen'
      const subDomain = this.state.subDomain

      const sortTiles = function (a, b) {
        const aQuestion = a.title || 'unknown'
        const bQuestion = b.title || 'unknown'
        return aQuestion < bQuestion ? -1 : aQuestion > bQuestion ? 1 : 0
      }

      let foundSubDomains = new Set()
      const audioList = []
      for (const audioId of Object.keys(audios.audios)) {
        const audio = JSON.parse(JSON.stringify(audios.audios[audioId]))

        if (filterSubDomains || isLernminiaturen) {
          let filteredListens = audio.listens.filter((viewEntry) => {
            let hostName = client2HostName[viewEntry.client] || 'unknown'
            foundSubDomains.add(hostName)

            return subDomain === '__all' || hostName === subDomain
          })
          if (filteredListens.length === 0) continue
          audio.listens = filteredListens
        }

        if (audio.context && Array.isArray(audio.context) && audio.context.length > 0) {
          audio.rawContextString = audio.context
            .map((context) => {
              return normalizeTitle(context.title)
            })
            .join(' ')
          audio.contextString = audio.context
            .map((context) => {
              return (
                context.type.substring(0, 1).toUpperCase() +
                context.type.substring(1).toLocaleLowerCase() +
                ': ' +
                normalizeTitle(context.title)
              )
            })
            .join(' -> ')
        } else {
          audio.contextString = 'aktuell nicht im Content verlinkt'
        }

        audio.title = normalizeTitle(audio.title)
        audioList.push(audio)
      }
      audioList.sort(sortTiles)

      let newHostNames = {}
      if (filterSubDomains || isLernminiaturen) {
        Object.keys(this.props.hostNames)
          .sort()
          .forEach((subd) => {
            if (subd === '__all' || foundSubDomains.has(subd)) newHostNames[subd] = this.props.hostNames[subd]
          })
      }

      this.setState({
        fetchDate: new Date(audios.fetchDate).toLocaleString(),
        audios: audioList,
        dataLines: [],
        isLoading: false,
        hostNames: filterSubDomains ? newHostNames : this.state.hostNames
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  updateFilter(value) {
    this.setState({ filter: value })
  }

  renderHeader() {
    const { auth, classes, match } = this.props
    const { filter, fetchDate, contentTag, audios, hostNames } = this.state
    const sourceSelector = sourceSelectorFromAuth(auth)
    const contentTags = contentTagsFromAuth(auth)
    const hasData = audios && audios.length > 0
    const isLearningMiniatures = contentTag === 'lernminiaturen'

    if (sourceSelector || isLearningMiniatures) {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item sm={4} xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.middleBox}>
            <LinkSelector
              match={match}
              label={getString('SELECTOR_LABEL_SUBDOMAIN')}
              fieldName="subDomain"
              selection={hostNames}
            />
          </Grid>
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            {hasData && (
              <FilterField
                value={filter}
                handleFilterChange={(value) => {
                  this.updateFilter(value)
                }}
              />
            )}
          </Grid>
        </Grid>
      )
    } else if (Object.keys(contentTags).length <= 1) {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {hasData && (
              <FilterField
                value={filter}
                handleFilterChange={(value) => {
                  this.updateFilter(value)
                }}
              />
            )}
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item sm={4} xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.middleBox}>
            <LinkSelector match={match} fieldName="contentTag" selection={contentTags} />
          </Grid>
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            <FilterField
              value={filter}
              handleFilterChange={(value) => {
                this.updateFilter(value)
              }}
            />
          </Grid>
        </Grid>
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return <WaitForLoad />
    } else {
      const { audios, filter } = this.state
      const hasData = audios && audios.length > 0
      const filteredAudios =
        filter === ''
          ? audios
          : audios.filter((audio) => {
              const regexp = new RegExp(filter, 'i')
              if (audio.rawContextString && audio.rawContextString.match(regexp)) return true
              if (audio.title && audio.title.match(regexp)) return true
              if (audio.audios && Array.isArray(audio.audios) && audio.audios.length) {
                for (const track of audio.audios) {
                  if (track.name.match(regexp)) return true
                }
              }
              return false
            })

      return (
        <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
          {this.renderHeader()}

          {hasData && (
            <Grid item xs={12}>
              {filteredAudios.map((audio) => {
                return <AudioView key={audio.id} audio={audio} />
              })}
            </Grid>
          )}
        </Grid>
      )
    }
  }
}

AudiosPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  hostNames: PropTypes.object.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(AudiosPage))
