
export class GrafanaAnswer {
  #nameMap = {}
  #typeMap = {}

  constructor(data) {
    data?.schema.forEach((columnDescription, index) => {
      if (Array.isArray(data.data[index])) {
        this.#nameMap[columnDescription.name] = data.data[index]
        this.#typeMap[columnDescription.name] = columnDescription.value
      }
    })
  }

  /**
   *
   * @return {number}
   */
  get size() {
    const columNames = this.columnNames()
    const firstColumnName = columNames.length ? columNames[0] : undefined
    return firstColumnName ? this.column(firstColumnName).length : 0
  }

  columnNames() {
    return Object.keys(this.#nameMap ?? {})
  }

  record(index) {
    const result = {}
    for (const [name, column] of Object.entries(this.#nameMap)) {
      result[name] = column[index]
    }
    return result
  }

  *records() {
    let current = 0
    while ( current < this.size ) {
      yield this.record(current)
      current += 1
    }
  }

  column(name) {
    return this.#nameMap[name] ?? []
  }

  field(name, index) {
    if (index < 0) return undefined
    return (this.#nameMap[name] ?? [])[index]
  }

  cloneEmptyData() {
    const data = { schema: [], data: [] }
    for ( const [fieldName, fieldType] of Object.entries(this.#typeMap) ) {
      data.schema.push({name: fieldName, value: fieldType} )
      data.data.push([])
    }
    return data
  }

  filterForColumnData(name, filterFunc) {
    const answer = this.cloneEmptyData()
    const columnCopy = this.column(name)
    columnCopy.forEach((value, index) => {
      if ( filterFunc(value) ) {
        Object.entries(this.#nameMap).forEach(([columnName, column], columnIndex) => {
          answer.data[columnIndex].push(column[index])
        })
      }
    })
    return answer
  }
}