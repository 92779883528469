import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'
import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import DrillDownChart, { stackLabelSum } from '../charts/DrillDownChart'
import ApiService from '../../services/APIService'
import DataTable from '../library/tableComponents/DataTable'
import { LinkSelector, ResolutionSelector } from '../LinkSelector'

import { validateResolutionFromProps } from '../../config/resolutions'
import {
  sortDate,
  formatDate,
  contentTagsFromAuth,
  validateContentTagFromProps,
  normalizeTitle,
  sourceSelectorFromAuth,
  validateSubDomainFromProps,
  hostNamesFromClients
} from '../utils/helperFunctions'
import { USAGE_FILTER_USER, USAGE_FOR_PATH } from '../../config/config'
import { refreshAuth } from './helpers/pageHelper'

const styles = (theme) => ({
  middleBox: {
    textAlign: 'right'
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

class UsagesPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      seriesBase: {},
      headLine: [],
      rawHeadLine: [],
      alignments: [],
      dataLines: [],
      fetchDate: '',
      rawDataLines: [],
      contentTag: validateContentTagFromProps(props),
      resolution: validateResolutionFromProps(props),
      subDomain: validateSubDomainFromProps(props),
      hostNames: Object.assign({}, props.hostNames)
    }
  }

  componentDidMount() {
    this.updateStats()
  }

  static getDerivedStateFromProps(props, state) {
    const resolution = validateResolutionFromProps(props)
    const contentTag = validateContentTagFromProps(props)
    const subDomain = validateSubDomainFromProps(props)
    const propsNames = props.hostNames ? Object.keys(props.hostNames) : []
    const stateNames = state.hostNames ? state.hostNames : {}
    const hostNamesChanged =
      propsNames.length !== Object.keys(stateNames).length || !propsNames.every((x) => stateNames.hasOwnProperty(x))

    if (
      resolution.name !== state.resolution.name ||
      contentTag !== state.contentTag ||
      subDomain !== state.subDomain ||
      hostNamesChanged
    ) {
      const changeSet = {}
      if (resolution.name !== state.resolution.name) changeSet.resolution = resolution
      if (contentTag !== state.contentTag) changeSet.contentTag = contentTag
      if (subDomain !== state.subDomain) changeSet.subDomain = subDomain
      if (hostNamesChanged) changeSet.hostNames = props.hostNames
      return changeSet
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { resolution, contentTag, subDomain } = this.state
    if (
      prevState.resolution.name !== resolution.name ||
      prevState.contentTag !== contentTag ||
      prevState.subDomain !== subDomain
    ) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {
        tabActions.updateIndicator()
      }, 0)
    }
  }

  async updateStats() {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const { contentTag, resolution, subDomain } = this.state

      const tiles = await ApiService.getTiles(newAuth, contentTag, false)
      const tileInfos = tiles && tiles['mapping'] ? tiles['mapping'] : {}
      const uuidTileInfos = {}
      for (const tileEntry of Object.values(tileInfos)) {
        if (tileEntry.uuid) {
          uuidTileInfos[tileEntry.uuid] = tileEntry
        }
      }

      const sortTiles = function (a, b) {
        const aId = a.split('!').pop()
        const bId = b.split('!').pop()

        const aTitle = tileInfos[aId].title || 'unknown'
        const bTitle = tileInfos[bId].title || 'unknown'

        return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0
      }

      const drupalTag = Object.keys(USAGE_FILTER_USER)[0]
      const isERGOPRO = drupalTag === 'ergopro'
      const isERV = drupalTag === 'erv'
      const isLearningMiniatures = drupalTag === 'lernminiaturen'
      const clients = await ApiService.getClients(newAuth, [contentTag])
      const hostNames = hostNamesFromClients(clients, newAuth)
      const userInfos = clients && clients['userInfo'] ? clients['userInfo'] : {}
      const client2User = {}
      const client2HostName = {}
      const client2Country = {}
      for (const clientId of Object.keys(clients.clientInfo)) {
        const clientInfo = clients.clientInfo[clientId]
        if (clientInfo && clientInfo.user) client2User[clientId] = clientInfo.user
        const fieldName = drupalTag === 'schichtarbeit' ? 'requestCode' : 'hostName'
        client2HostName[clientId] = clientInfo && clientInfo[fieldName] ? clientInfo[fieldName] : 'unknown'
        client2Country[clientId] = clientInfo && clientInfo.homeCountry ? clientInfo.homeCountry : 'other'
        if (client2Country[clientId] === '-') client2Country[clientId] = 'other'
      }

      const data = await ApiService.getUsages(
        newAuth,
        resolution.apiParam,
        contentTag,
        isERV ? new Date('2019-05-01T00:00:00.000Z') : null
      )

      const filterSubDomains = sourceSelectorFromAuth(newAuth)
      let foundSubDomains = new Set()

      // filter data to logLines
      const logLines = []
      data.lines.forEach((item) => {
        let user = client2User[item.client] || null
        if (USAGE_FILTER_USER[contentTag]) {
          if (user) {
            const userInfo = userInfos[user]
            if (!userInfo) {
              return
            }
            item.region = userInfo && userInfo.region ? userInfo.region : 'none'
            if (isERGOPRO) {
              foundSubDomains.add(item.region)
              if (subDomain !== '__all' && item.region !== subDomain) {
                return
              }
            }
            if (isLearningMiniatures) {
              item.region = userInfo && userInfo.mailDomain ? userInfo.mailDomain : 'other'
              foundSubDomains.add(item.region)
              if (subDomain !== '__all' && item.region !== subDomain) {
                return
              }
            }
          }
        }

        if (isERV) {
          item.region = client2Country[item.client] || 'other'
          foundSubDomains.add(item.region)
          if (!hostNames.hasOwnProperty(item.region) || (subDomain !== '__all' && item.region !== subDomain)) {
            return
          }
          if (item.path === '10') item.path = '13'
          if (item.path === '10!13') item.path = '13'
          item.path = item.path.replace(/^10!/, '13!')
          item.path = item.path.replace(/^4!/, '40!')
        }

        if (USAGE_FOR_PATH) item.cId = item.path.split('!').pop()
        if (uuidTileInfos[item.cId]) {
          item.cId = uuidTileInfos[item.cId].id
        }
        if (!tileInfos[item.cId]) {
          return
        }

        if (filterSubDomains) {
          let hostName = client2HostName[item.client] || 'unknown'
          let subHost = hostName.split(/\./)[0]
          foundSubDomains.add(subHost)

          if (subDomain !== '__all' && subHost !== subDomain) {
            return
          }
        }

        item.user = 'Anzahl'
        item.ld = formatDate(item.ld)

        logLines.push(item)
      })

      let newHostNames = {}
      if (filterSubDomains || isERV || isERGOPRO || isLearningMiniatures) {
        for (const subDomain of Object.keys(this.props.hostNames).sort()) {
          if (subDomain === '__all' || foundSubDomains.has(subDomain))
            newHostNames[subDomain] = this.props.hostNames[subDomain]
        }
      }

      // analyze tiles, add parents to spocs and lectures
      const courseIds = new Set()
      const spocIds = new Set()
      const lectureIds = new Set()

      Object.keys(tileInfos).forEach((key) => {
        const tileInfo = tileInfos[key]
        if (tileInfo.type) {
          switch (tileInfo.type) {
            case 'course':
              courseIds.add(key)
              if (tileInfo.spocs && tileInfo.spocs.length) {
                const parentPath = [key]
                for (const spocId of tileInfo.spocs) {
                  const spocInfo = tileInfos[spocId]
                  if (spocInfo && !spocInfo.parents) spocInfo.parents = parentPath
                }
              }
              if (tileInfo.lectures && tileInfo.lectures.length) {
                const parentPath = [key, null]
                for (const lectureId of tileInfo.lectures) {
                  const lectureInfo = tileInfos[lectureId]
                  if (lectureInfo && !lectureInfo.parents) lectureInfo.parents = parentPath
                }
              }
              break
            case 'spoc':
              spocIds.add(key)
              break
            case 'lecture':
              lectureIds.add(key)
              break
            default:
          }
        }
      })

      spocIds.forEach((spocId) => {
        const spocInfo = tileInfos[spocId]
        if (courseIds.size > 0 && !spocInfo.parents) {
          spocInfo.parents = [null]
        }

        const parentPath = spocInfo.parents && spocInfo.parents.length ? spocInfo.parents.slice() : []
        parentPath.push(spocId)

        if (spocInfo.lectures && spocInfo.lectures.length) {
          for (const lectureId of spocInfo.lectures) {
            const lectureInfo = tileInfos[lectureId]
            // only the first parentPath is set
            if (lectureInfo && !lectureInfo.parents) {
              lectureInfo.parents = parentPath
            }
          }
        }
      })

      lectureIds.forEach((lectureId) => {
        const lectureInfo = tileInfos[lectureId]
        if (!lectureInfo.parents) {
          if (courseIds.size > 0) {
            lectureInfo.parents = [null, null]
          } else if (spocIds.size > 0) {
            lectureInfo.parents = [null]
          }
        }
      })

      // collect logLines to date based tree
      const regionDates = {}
      const regionNames = {}
      logLines.forEach((item) => {
        const date = item.ld
        const regionName = isERGOPRO || isERV || isLearningMiniatures ? item.region : item.user
        const contentId = USAGE_FOR_PATH ? item.path : item.cId

        regionNames[regionName] = 1

        if (!regionDates[date]) regionDates[date] = {}
        if (!regionDates[date][contentId]) regionDates[date][contentId] = {}
        if (!regionDates[date][contentId][regionName]) regionDates[date][contentId][regionName] = item.hits || 0
        else regionDates[date][contentId][regionName] += item.hits || 0
      })

      //set all data to rawDataLines
      const rawDataLines = []
      data.lines.forEach((info) => {
        const clientInfo = clients.clientInfo[info.client]
        if (clientInfo && clientInfo.user) {
          const userInfo = clients.userInfo[clientInfo.user]
          if (userInfo) {
            const tags = userInfo.tags

            rawDataLines.push([
              info.ld,
              info.client,
              info.hits,
              info.cId,
              info.region,
              userInfo.username,
              userInfo.dateCreated,
              userInfo.mailDomain,
              tags ? (Array.isArray(tags) ? tags.join(',') : tags) : '',
              clientInfo.requestCode
            ])
          }
        }
      })

      // collect dataLines for table view from date based tree
      const dataLines = []
      let hasCourseEntries = false
      let hasSpocEntries = false
      Object.keys(regionDates)
        .sort(sortDate)
        .forEach((date) => {
          Object.keys(regionDates[date])
            .sort(sortTiles)
            .forEach((pathString) => {
              // date course spoc lecture type count
              let sum = 0
              const line = [date]
              const path = USAGE_FOR_PATH ? pathString.split('!') : [...(tileInfos[pathString].parents || [])]
              const contentId = USAGE_FOR_PATH ? path.pop() : pathString
              const tileInfo = tileInfos[contentId]
              const contentType = tileInfo.type

              // restore hierarchy
              if (courseIds.size > 0) {
                if (contentType === 'course') {
                  hasCourseEntries = true
                  line.push(normalizeTitle(tileInfo.title))
                  line.push('')
                  line.push('')
                } else if (contentType === 'spoc') {
                  let courseInfo = path[0] ? tileInfos[path[0]] : null
                  if (!courseInfo)
                    courseInfo = tileInfo.parents && tileInfo.parents[0] ? tileInfos[tileInfo.parents[0]] : null
                  line.push(courseInfo ? normalizeTitle(courseInfo.title) : 'unbekannt')
                  line.push(normalizeTitle(tileInfo.title))
                  line.push('')
                } else if (contentType === 'lecture') {
                  let courseInfo = path[0] ? tileInfos[path[0]] : null
                  if (!courseInfo)
                    courseInfo = tileInfo.parents && tileInfo.parents[0] ? tileInfos[tileInfo.parents[0]] : null
                  line.push(courseInfo ? normalizeTitle(courseInfo.title) : 'unbekannt')

                  let lectureInfo = path[1] ? tileInfos[path[1]] : null
                  if (!lectureInfo)
                    lectureInfo = tileInfo.parents && tileInfo.parents[1] ? tileInfos[tileInfo.parents[1]] : null
                  line.push(lectureInfo ? normalizeTitle(lectureInfo.title) : 'unbekannt')

                  line.push(normalizeTitle(tileInfo.title))
                }
              } else {
                if (contentType === 'spoc') {
                  hasSpocEntries = true
                  line.push(normalizeTitle(tileInfo.title))
                  line.push('')
                } else {
                  // push spoc title
                  let lectureInfo = path[0] ? tileInfos[path[0]] : null
                  if (!lectureInfo)
                    lectureInfo = tileInfo.parents && tileInfo.parents[0] ? tileInfos[tileInfo.parents[0]] : null
                  line.push(lectureInfo ? normalizeTitle(lectureInfo.title) : 'unbekannt')

                  line.push(normalizeTitle(tileInfo.title))
                }
              }

              line.push(contentType)
              Object.keys(regionNames)
                .sort()
                .forEach((regionName) => {
                  const value = regionDates[date][pathString][regionName] || 0
                  line.push(value)
                  sum += value
                })
              if (isERGOPRO || isERV || isLearningMiniatures) line.push(sum)
              dataLines.push(line)
            })
        })

      // collect hierarchy for drillDown from date based tree
      const hierarchy = {}
      const description = {
        0: { title: '', drillUpText: '', colorByPoint: true, order: Object.keys(regionDates).sort(sortDate) },
        1: {
          title: 'Period: {{PLACEHOLDER}}',
          drillUpText: 'periods',
          type: 'column',
          keys: new Set()
        },
        2: {
          drillUpText: 'Courses',
          type: 'column',
          keys: new Set()
        },
        3: {
          drillUpText: 'Spocs',
          type: 'column',
          keys: new Set()
        }
      }

      const collectDateHierarchy = (dateData, level, pathString) => {
        const contentId = pathString.split('!').pop()
        const contentData = dateData[USAGE_FOR_PATH ? pathString : contentId]
        const sum = Object.values(contentData).reduce(
          (previousValue, currentValue) => previousValue + (currentValue || 0),
          0
        )
        description[level].keys.add(contentId)
        if (lectureIds.has(contentId)) {
          return sum
        } else if (spocIds.has(contentId)) {
          const spocInfo = tileInfos[contentId]
          const result = {}
          spocInfo.lectures.forEach((lectureId) => {
            if (dateData[USAGE_FOR_PATH ? contentId + '!' + lectureId : lectureId]) {
              result[lectureId] = collectDateHierarchy(dateData, level + 1, contentId + '!' + lectureId)
            }
          })
          if (isERV) {
            result._ = Object.values(result).reduce(
              (previousValue, currentValue) => previousValue + (currentValue || 0),
              0
            )
          } else {
            result._ = sum
          }
          return result
        } else if (courseIds.has(contentId)) {
          const courseInfo = tileInfos[contentId]
          const result = { _: sum }
          courseInfo.lectures.forEach((lectureId) => {
            if (dateData[USAGE_FOR_PATH ? contentId + '!' + lectureId : lectureId]) {
              result[lectureId] = collectDateHierarchy(dateData, level + 1, contentId + '!' + lectureId)
            }
          })
          courseInfo.spocs.forEach((spocId) => {
            if (dateData[USAGE_FOR_PATH ? contentId + '!' + spocId : spocId]) {
              result[spocId] = collectDateHierarchy(dateData, level + 1, contentId + '!' + spocId)
            }
          })
          return result
        }
      }

      Object.keys(regionDates).forEach((date) => {
        const dateData = regionDates[date]

        Object.keys(dateData).forEach((pathString) => {
          const contentId = pathString.split('!').pop()
          if (hasCourseEntries) {
            if (courseIds.has(contentId)) {
              if (!hierarchy[date]) hierarchy[date] = {}
              hierarchy[date][contentId] = collectDateHierarchy(dateData, 1, pathString)
            }
          } else if (hasSpocEntries) {
            if (spocIds.has(contentId)) {
              if (!hierarchy[date]) hierarchy[date] = {}
              hierarchy[date][contentId] = collectDateHierarchy(dateData, 1, pathString)
            }
          } else {
            if (!hierarchy[date]) hierarchy[date] = {}
            hierarchy[date][contentId] = collectDateHierarchy(dateData, 1, pathString)
          }
        })
      })

      // adjust level descriptions, dependent on data
      for (const level of Object.keys(description)) {
        if (parseInt(level) === 0) continue

        if (description[level].keys.size === 0) {
          delete description[level]
          continue
        }

        description[level].order = Array.from(description[level].keys)
        delete description[level].keys

        description[level].order.sort(sortTiles)
        description[level].keyTranslation = {}
        for (const tileId of description[level].order)
          description[level].keyTranslation[tileId] = normalizeTitle(tileInfos[tileId].title)

        switch (parseInt(level)) {
          case 1:
            if (hasCourseEntries) description[level].level = 'course'
            else description[level].level = hasSpocEntries ? 'spoc' : 'lecture'
            break
          case 2:
            description[level].title = hasCourseEntries ? 'Course {{PLACEHOLDER}}' : 'Spoc {{PLACEHOLDER}}'
            description[level].level = hasCourseEntries ? 'spoc' : 'lecture'
            description[level].drillUpText = hasCourseEntries ? 'Courses' : 'Spocs'
            break
          case 3:
            description[level].title = 'Spoc {{PLACEHOLDER}}'
            description[level].level = 'lecture'
            break
          default:
        }
      }

      const courseTitle = courseIds.size > 0 ? [getString('COURSE_TITLE')] : []
      const headLine = [
        getString('DATE'),
        ...courseTitle,
        getString('SPOC TITLE'),
        getString('LECTURE TITLE'),
        getString('TYPE'),
        ...Object.keys(regionNames).sort()
      ]
      if (isERGOPRO || isERV || isLearningMiniatures) headLine.push(getString('TOTAL'))
      const alignments = headLine.slice(0)
      alignments.fill('left', 0, 3)
      alignments.fill('right', 3)

      const rawHeadLine = [
        getString('DATE'),
        getString('Client'),
        getString('Hits'),
        getString('Content Id'),
        getString('Region'),
        getString('User Name'),
        getString('User Created'),
        getString('Mail Domain'),
        getString('Tags'),
        getString('RequestCode')
      ]

      this.setState({
        seriesBase: { data: hierarchy, description: description },
        dataLines: dataLines,
        rawDataLines: rawDataLines,
        headLine: headLine,
        rawHeadLine: rawHeadLine,
        alignments: alignments,
        fetchDate: new Date(data.fetchDate).toLocaleString(),
        hostNames: filterSubDomains ? newHostNames : hostNames,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader() {
    const { auth, classes, match } = this.props
    const { fetchDate, hostNames } = this.state
    const sourceSelector = sourceSelectorFromAuth(auth)
    const contentTags = contentTagsFromAuth(auth)
    const drupalTag = Object.keys(USAGE_FILTER_USER)[0]
    const isERGOPRO = drupalTag === 'ergopro'
    const isERV = drupalTag === 'erv'
    const isLearningMiniatures = drupalTag === 'lernminiaturen'

    if (sourceSelector || isERGOPRO || isERV || isLearningMiniatures) {
      const label = getString(
        isERV ? 'SELECTOR_LABEL_COUNTRY' : isERGOPRO ? 'SELECTOR_LABEL_SALESREGION' : 'SELECTOR_LABEL_ORIGIN'
      )
      return (
        <Grid container alignItems="center" style={{ padding: 24 }}>
          <Grid item sm={4} xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.middleBox}>
            <LinkSelector fieldName="subDomain" label={label} match={match} selection={hostNames} />
          </Grid>
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            <ResolutionSelector match={match} />
          </Grid>
        </Grid>
      )
    } else if (Object.keys(contentTags).length <= 1) {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <ResolutionSelector match={match} />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item sm={4} xs={6}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.middleBox}>
            <LinkSelector fieldName="contentTag" match={match} selection={contentTags} />
          </Grid>
          <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
            <ResolutionSelector match={match} />
          </Grid>
        </Grid>
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return <WaitForLoad />
    } else {
      const { seriesBase, headLine, rawHeadLine, alignments, dataLines, rawDataLines, resolution, fetchDate } =
        this.state
      const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
      const hasData = dataLines && dataLines.length > 0
      const filenamePrefix = 'Usages-' + resolution.chartName

      return (
        <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
          {this.renderHeader()}

          {hasSeries && (
            <Grid item xs={12}>
              <DrillDownChart
                title=""
                seriesBase={seriesBase}
                stackLabelConfig={stackLabelSum}
                legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120} />}
              />
            </Grid>
          )}

          {hasData && (
            <DataTable
              headLine={headLine}
              dataLines={dataLines}
              rawHeadLine={rawHeadLine}
              rawDataLines={rawDataLines}
              alignments={alignments}
              fetchDate={fetchDate}
              excelTitle={getString('MENU_USAGES')}
              filenamePrefix={filenamePrefix}
              chunks={1}
            />
          )}
        </Grid>
      )
    }
  }
}

UsagesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  hostNames: PropTypes.object.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(UsagesPage))
