import React, {PureComponent, memo} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'

const Cell = memo(({value, columnNumber}) => {
    const dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric'}
    return (<TableCell
        component={columnNumber === 0 ? 'th' : 'td'}
        align={columnNumber === 0 ? 'left' : 'right'}
    >
        {value instanceof Date
            ? value.toLocaleDateString('de-DE', dateOptions)
            : typeof value === 'object'
                ? <Tooltip title={'am ' + value.tooltip.toLocaleDateString('de-DE', dateOptions)}
                           arrow
                           placement='bottom'><span>{value.value}</span></Tooltip>
                : value}
    </TableCell>)
})

const Row = memo(({line}) => {
    return (<TableRow scope="row">
        {line.map((value, columnNumber) => <Cell value={value} key={columnNumber} columnNumber={columnNumber}/>)}
    </TableRow>)
})

class ShiftWorkProgressTable extends PureComponent {

    constructor(props) {
        super(props)

        const standardAlignments = props.headLine.slice(0)
        standardAlignments[0] = 'left'
        standardAlignments.fill('right', 1)

        this.state = {
            alignments: standardAlignments,
            dataLines: props.dataLines.slice(0, props.chunks)
        }

        this.unmount = false
        this.timer = null
    }

    recursive = () => {
        this.timer = setTimeout(() => {
            if (this.unmount) return

            const {chunks, dataLines} = this.props
            const hasMore = this.state.dataLines.length + chunks < dataLines.length

            if (this.state.dataLines.length < dataLines.length) {
                this.setState((prev, props) => ({
                    dataLines: props.dataLines.slice(0, prev.dataLines.length + chunks)
                }))
            }

            if (hasMore) {
                this.recursive()
            } else {
                this.timer = null
                console.log('INFO', 'ShiftWorkProgressTable.recursive', 'all lines rendered', dataLines.length)
            }
        }, 0)
    }

    componentWillUnmount() {
        if (this.timer !== null) {
            clearTimeout(this.timer)
            this.timer = null
        }
        this.unmount = true
    }

    componentDidMount() {
        this.recursive()
    }

    render() {
        const {headLine} = this.props
        const {dataLines} = this.state

        return (<Grid item xs={12}>
            <Table cellSpacing={0} size="small">
                <TableHead>
                    <TableRow>
                        {headLine.map((header, index) => (
                            <TableCell component="th" key={index} align={index === 0 ? 'left' : 'right'}>
                                {header}
                            </TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataLines.map((line, lineNumber) => <Row line={line} key={lineNumber}/>)}
                </TableBody>
            </Table>
        </Grid>)
    }
}

ShiftWorkProgressTable.propTypes = {
    headLine: PropTypes.array.isRequired,
    dataLines: PropTypes.array.isRequired,
    chunks: PropTypes.number
}

ShiftWorkProgressTable.defaultProps = {
    chunks: 50
}

export default ShiftWorkProgressTable
