import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Highcharts from 'highcharts'
import { Chart, ColumnSeries, HighchartsChart, HighchartsProvider, XAxis, YAxis, Title } from 'react-jsx-highcharts'

import ChartResolutionSelector from './ChartResolutionSelector'
import { getDefaultResolution, getResolutionData } from '../config/resolutions'
import { formatDate, sortDate } from './utils/helperFunctions'

function dateToMonth(date) {
  const year = date.getUTCFullYear().toString()
  let month = (date.getUTCMonth() + 1).toString()
  if (month.length === 1) month = '0' + month
  return Number.parseInt(year + month)
}

function dateToWeek(date) {
  let d = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7).toString()
  if (weekNo.length === 1) weekNo = '0' + weekNo
  return Number.parseInt(d.getUTCFullYear().toString() + weekNo)
}

const ViewsHistoryChart = ({ views }) => {
  const [actResolution, setActResolution] = useState(getDefaultResolution)

  const changeResolution = (event) => {
    setActResolution(getResolutionData(event.target.value))
  }

  const entries = {}
  views.forEach((viewEntry) => {
    let logDate = new Date(viewEntry.utc)
    let logDay = new Date(Date.UTC(logDate.getUTCFullYear(), logDate.getUTCMonth(), logDate.getUTCDate()))

    let date
    switch (actResolution.name) {
      case 'daily':
        date = formatDate(logDay.toJSON().replace(/\.000Z$/, 'Z'))
        break
      case 'weekly':
        date = formatDate(dateToWeek(logDay))
        break
      case 'yearly':
        date = formatDate(logDay.getFullYear())
        break
      default: // 'monthly'
        date = formatDate(dateToMonth(logDay))
    }

    if (!entries[date]) {
      entries[date] = 1
    } else {
      entries[date] += 1
    }
  })

  const xAxisData = Object.keys(entries)
  xAxisData.sort(sortDate)

  const series = []
  for (const date of xAxisData) series.push(entries[date])

  return (
    <Box align={'right'}>
      <ChartResolutionSelector actResolution={actResolution} onChange={changeResolution} />
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart>
          <Chart height={240} />
          <Title>Wiedergaben</Title>
          <XAxis categories={xAxisData} />
          <YAxis allowDecimals={false} max={null}>
            <YAxis.Title>{'Views'}</YAxis.Title>
            <ColumnSeries dataLabels={{ enabled: true, style: { fontSize: '15px' } }} data={series} />
          </YAxis>
        </HighchartsChart>
      </HighchartsProvider>
    </Box>
  )
}

ViewsHistoryChart.propTypes = {
  views: PropTypes.array.isRequired
}

export default React.memo(ViewsHistoryChart)
